import React, { FC } from 'react';
import { SortKey } from 'shared/hooks/sorting';
import { useFetchTopicsQuery } from '../../redux';
import { ITopicFilters } from '../../types';
import { TopicsList } from '../list/TopicsList';

interface IProps {
  page: number;
  limit: number;
  filters: ITopicFilters;
  sort: SortKey;
}

export const TopicsViewPage: FC<IProps> = ({ page, limit, filters, sort }) => {
  const { data: topicsData, isFetching } = useFetchTopicsQuery({
    pagination: { limit, page },
    filters,
    sort,
  });

  if (!topicsData?.data) return null;

  return <TopicsList topics={topicsData.data} loading={isFetching} />;
};
