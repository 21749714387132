import { ROUTES } from 'shared/constants';

export const NAVIGATION_OPTIONS = [
  {
    label: 'main',
    route: ROUTES.main,
    disabled: true,
    iconName: 'Main',
  },
  {
    label: 'topics',
    route: ROUTES.topics,
    iconName: 'Topics',
  },
  {
    label: 'polls',
    route: ROUTES.polls,
    iconName: 'Polls',
  },
  {
    label: 'events',
    route: ROUTES.events,
    iconName: 'Events',
  },
  {
    label: 'news',
    route: ROUTES.news,
    disabled: true,
    iconName: 'News',
  },
  {
    label: 'documents',
    route: ROUTES.docs,
    disabled: true,
    iconName: 'Documents',
  },
];
