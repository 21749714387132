import React from 'react';
import { FC } from 'react';
import { dateUtils } from 'shared/service';
import { InputDate } from '../InputDate/InputDate';
import './InputDateRange.scss';

interface IProps {
  from: number | undefined;
  to: number | undefined;
  onChange: (values: { from?: number; to?: number }) => void;
}

export const InputDateRange: FC<IProps> = ({ from, to, onChange }) => {
  const handleFromChange = (_from: number) => {
    onChange({ from: _from, to });
  };

  const handleToChange = (_to: number) => {
    onChange({ from, to: _to });
  };

  const fromDate = from ? dateUtils.getDate(from) : undefined;
  const toDate = to ? dateUtils.getDate(to) : undefined;

  return (
    <>
      <InputDate
        value={from}
        selected={[fromDate, { from: fromDate, to: toDate }]}
        disabled={toDate ? { after: toDate } : undefined}
        onChange={handleFromChange}
      />
      <InputDate
        value={to}
        classNames={{
          overlay: 'DayPickerInput-Overlay--right',
        }}
        selected={[{ from: fromDate, to: toDate }, toDate]}
        disabled={fromDate ? { before: fromDate } : undefined}
        onChange={handleToChange}
      />
    </>
  );
};
