import { EntityFilters } from 'shared/hooks/filters';

export interface IPollFilters extends EntityFilters {
  filterHeaderText?: string;
  filterActiveFrom?: number;
  filterActiveTo?: number;
  filterFromDate?: number;
  filterToDate?: number;
}

export type PollAnswers = {
  questionId: string;
  answerId: string;
}[];

export type SubmitPollModel = {
  id: string;
  data: PollAnswers;
};

export enum ParticipationStatus {
  yes = 'yes',
  no = 'no',
  skip = 'skip',
}
