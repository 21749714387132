import React from 'react';
import TextField from '@material-ui/core/TextField';
import './RenderTextField.css';

export const RenderTextField = ({ label, children }) => {
  return (
    <TextField
      className="render-text-field"
      InputProps={{ disableUnderline: true }}
      label={label}
      value={children}
    >
      {children}
    </TextField>
  );
};
