import POLLS_STRINGS from '../modules/polls/locales/poll-eng';
import TOPICS_STRINGS from '../modules/topics/locales/topic-eng';
import EVENTS_STRINGS from '../modules/events/locales/event-eng';
import NAVIGATION_STRINGS from '../modules/navigation/locales/nav-eng';
import AUTH_STRINGS from '../modules/auth/locales/auth-eng';
import ACCOUNT_STRINGS from '../modules/accounts/locales/accounts-eng';
import MESSAGES_STRINGS from '../modules/accounts/components/Messages/locales/messages-eng';
import SHARED_STRINGS from '../shared/locales/shared-eng';

export default {
  POLLS_STRINGS,
  TOPICS_STRINGS,
  EVENTS_STRINGS,
  NAVIGATION_STRINGS,
  AUTH_STRINGS,
  ACCOUNT_STRINGS,
  MESSAGES_STRINGS,
  SHARED_STRINGS,
};
