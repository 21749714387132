import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './Text.css';

const SIZES = ['m', 's', 'xs', 'xxs', 'l', 'xl', 'xxl', 'xxxl'];
const COLORS = {
  darkIndigo: 'dark-indigo',
};

type Sizes = 'm' | 's' | 'xs' | 'xxs' | 'l' | 'xl' | 'xxl' | 'xxxl';

interface IProps {
  size?: Sizes;
  bold?: boolean;
  thin?: boolean;
  color?: string;
  className?: string;
  multiline?: boolean;
}

interface ITextComponent extends FunctionComponent<IProps> {
  sizes: Record<Sizes, Sizes>;
  colors: Record<string, string>;
}

const Text: ITextComponent = ({
  size = 'm',
  bold: _bold,
  thin,
  color: _color,
  className,
  multiline,
  children,
}) => {
  let color = _color,
    bold = _bold;
  if (SIZES.indexOf(size) > SIZES.indexOf('m')) {
    color = COLORS.darkIndigo;
  }
  if (SIZES.indexOf(size) > SIZES.indexOf('l') && _bold === undefined) {
    bold = true;
  }
  return (
    <span
      className={classNames('cmp-text', className, `cmp-text--${color}`, {
        'cmp-text--multiline': multiline,
        'cmp-text--m': size === Text.sizes.m,
        'cmp-text--s': size === Text.sizes.s,
        'cmp-text--xs': size === Text.sizes.xs,
        'cmp-text--xxs': size === Text.sizes.xxs,
        'cmp-text--l': size === Text.sizes.l,
        'cmp-text--xl': size === Text.sizes.xl,
        'cmp-text--xxl': size === Text.sizes.xxl,
        'cmp-text--xxxl': size === Text.sizes.xxxl,
        'cmp-text--bold': bold,
        'cmp-text--thin': thin,
      })}
    >
      {children}
    </span>
  );
};

Text.sizes = { m: 'm', s: 's', xs: 'xs', xxs: 'xxs', l: 'l', xl: 'xl', xxl: 'xxl', xxxl: 'xxxl' };
Text.colors = COLORS;

export { Text };
