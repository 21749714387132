class LocalStorageElement {
  constructor(name) {
    this.name = name;
    this.value = localStorage.getItem(name);
  }
  set(value) {
    this.value = value;
    localStorage.setItem(this.name, value);
  }
  get() {
    return this.value;
  }
}

export class LocalStorage {
  static token = new LocalStorageElement('token');
}
