import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  buttonVariants,
  CardSection,
  FormHeader,
  Input,
  Text,
  Textarea,
  ActionButton,
  ModuleContent,
} from 'shared/components';
import { InputDateControl } from 'shared/components/form-controls/InputDate/InputDate';
import { Question } from '../../components/form/Question/Question';
import { useCreatePollMutation } from '../../redux/polls.slice';
import { IAnswer } from '../../types/models';
import './PollForm.css';

export type PollFormValues = {
  title: string;
  description: string;
  activeUntil: number;
  questions: { answers: Partial<IAnswer>[]; text: string }[];
};

export const PollForm: VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<PollFormValues>({ mode: 'onChange' });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const [createPoll, { isLoading: loading }] = useCreatePollMutation();

  const onSubmit = async (data: PollFormValues) => {
    const result = await createPoll(data);
    if ('data' in result) {
      history.push('/polls');
    }
  };

  return (
    <ModuleContent light>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader text={t('POLLS_STRINGS.form.header')} closeTo="/polls" />

        <Box className="poll-form__main-controls">
          <CardSection>
            <Text size={Text.sizes.xl}>{t('SHARED_STRINGS.card.main_info')}</Text>
          </CardSection>
          <Input
            {...register('title', { required: true, maxLength: 150 })}
            label={t('POLLS_STRINGS.form.title')}
            error={errors?.title?.message || errors?.title?.type}
          />
          <Textarea
            {...register('description', { required: true })}
            label={t('POLLS_STRINGS.form.description')}
            error={errors?.description?.message || errors?.description?.type}
          />
          <InputDateControl
            label={t('POLLS_STRINGS.form.setActiveUntil')}
            name="activeUntil"
            //@ts-ignore
            control={control}
          />
        </Box>

        <Box className="poll-form__questions">
          <CardSection>
            <Text size={Text.sizes.xl}>{t('POLLS_STRINGS.form.questions')}</Text>
          </CardSection>
          {fields.map((item, index) => (
            <Box key={item.id} className="poll-form__question">
              <Question
                //@ts-ignore
                question={item}
                questionIndex={index}
                //@ts-ignore
                control={control}
                register={register}
                //@ts-ignore
                errors={errors?.questions}
              />
              <Button variant={buttonVariants.TEXT} onClick={() => remove(index)}>
                {t('POLLS_STRINGS.form.deleteQuestion')}
              </Button>
            </Box>
          ))}
          <Button
            fullWidth
            variant={buttonVariants.OUTLINED}
            onClick={() =>
              append({
                text: '',
                answers: [
                  { isChosen: false, text: '' },
                  { isChosen: false, text: '' },
                ],
              })
            }
          >
            <Text size={Text.sizes.m} color={Text.colors.darkIndigo} bold>
              {t('POLLS_STRINGS.form.addQuestion')}
            </Text>
          </Button>
        </Box>

        <ActionButton
          fullWidth
          type="submit"
          disabled={!isDirty || !isValid || loading}
          loading={loading}
        >
          {t('POLLS_STRINGS.form.publish')}
        </ActionButton>
      </form>
    </ModuleContent>
  );
};
