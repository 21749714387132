import POLLS_STRINGS from '../modules/polls/locales/poll-rus';
import TOPICS_STRINGS from '../modules/topics/locales/topic-rus';
import EVENTS_STRINGS from '../modules/events/locales/event-rus';
import NAVIGATION_STRINGS from '../modules/navigation/locales/nav-rus';
import AUTH_STRINGS from '../modules/auth/locales/auth-rus';
import ACCOUNT_STRINGS from '../modules/accounts/locales/accounts-rus';
import MESSAGES_STRINGS from '../modules/accounts/components/Messages/locales/messages-rus';
import SHARED_STRINGS from '../shared/locales/shared-rus';

export default {
  POLLS_STRINGS,
  TOPICS_STRINGS,
  EVENTS_STRINGS,
  NAVIGATION_STRINGS,
  AUTH_STRINGS,
  ACCOUNT_STRINGS,
  MESSAGES_STRINGS,
  SHARED_STRINGS,
};
