import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionButton, Input } from 'shared/components';

export const SetPasswordForm = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({ mode: 'onChange' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('old_password', { required: true })}
        label={t('ACCOUNT_STRINGS.form.old_password')}
        type="password"
        error={errors?.old_password?.message || errors?.old_password?.type}
      />
      <Input
        {...register('new_password', { required: true })}
        label={t('ACCOUNT_STRINGS.form.new_password')}
        type="password"
        error={errors?.new_password?.message || errors?.new_password?.type}
      />
      <Input
        {...register('new_password_repeat', {
          required: true,
          validate: (v) =>
            v !== getValues('new_password')
              ? t('ACCOUNT_STRINGS.form.errors.passwords_not_same')
              : true,
        })}
        label={true('ACCOUNT_STRINGS.form.new_password_repeat')}
        type="password"
        error={
          touchedFields.new_password_repeat
            ? errors?.new_password_repeat?.message || errors?.new_password_repeat?.type
            : null
        }
      />
      <ActionButton fullWidth type="submit" disabled={isLoading} loading={isLoading}>
        {t('ACCOUNT_STRINGS.form.submit_change_password')}
      </ActionButton>
    </form>
  );
};
