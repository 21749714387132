import React from 'react';
import { Box } from 'shared/components';
import { ReactComponent as LogoComponent } from 'assets/logo.svg';

export const Logo = ({ className }) => {
  return (
    <Box flexX className={className}>
      <LogoComponent />
    </Box>
  );
};
