import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'config/styles';
import { IoFilterOutline } from 'react-icons/io5';
import { Box, Button, Text } from 'shared/components';
import './PollsViewHeader.css';

interface IProps {
  pollsCount: number;
  filterActive: boolean;
  onFilterClick: () => void;
}

export const PollsViewHeader: FC<IProps> = ({ pollsCount, filterActive, onFilterClick }) => {
  const { t } = useTranslation();
  const iconParams = filterActive ? { color: colors.dark.primary, fontWeight: 'bold' } : {};
  return (
    <>
      <Box flexX className="polls-view-header__header">
        <Text size={Text.sizes.xxxl}>{t('POLLS_STRINGS.polls')}</Text>
        <Button icon={<IoFilterOutline {...iconParams} />} onClick={onFilterClick}></Button>
      </Box>
      <Text size={Text.sizes.l} thin className="polls-view-header">
        {pollsCount === undefined
          ? t('POLLS_STRINGS.loading')
          : t('POLLS_STRINGS.polls_count', { pollsCount })}
      </Text>
    </>
  );
};
