const AUTH_STRINGS_EST = {
  login_title: 'Log in to Occolo',
  form: {
    email: 'Email',
    email_placeholder: 'Email address',
    password: 'Password',
    forgot_question: 'Forgot password?',
    submit: 'Login',
    questions: 'In case of any questions please contact',
  },
  forgot: {
    title: 'Reset password',
    description: 'Enter your email and we will send you further instructions',
    submit: 'Reset',
  },
  validation: {
    email: 'Email is required.',
    emailInvalid: 'The email you entered is invalid.',
    password: 'Password is required.',
    passwordMinLength: 'Password is too short.',
    passwordMaxLength: 'Password must be shorter than 255 characters.',
  },
};

export default AUTH_STRINGS_EST;
