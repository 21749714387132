import { createSlice } from '@reduxjs/toolkit';

const errorsSlice = createSlice({
  name: 'globalErrors',
  initialState: {},
  reducers: {
    setError(state, action) {
      const errorId = action.payload.id;
      state[errorId] = action.payload;
    },
    cancelError(state, action) {
      state[action.payload] = undefined;
    },
  },
});

export const { setError, cancelError } = errorsSlice.actions;
export const { reducer } = errorsSlice;
