import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { apiService } from 'shared/service';
import { eventUtils } from '../utils';

export const fetchEvents = createAsyncThunk('events/fetchAll', async (params) => {
  return apiService.read('events', params);
});
export const fetchEvent = createAsyncThunk('events/fetchOne', async (id) => {
  return apiService.read(`events/${id}`);
});
export const addEvent = createAsyncThunk('events/create', async (payload) => {
  return apiService.create('events', payload);
});

export const filter = (params) => async (dispatch) => {
  dispatch(applyFilters());
  return await dispatch(fetchEvents(params));
};
export const resetFilter = (params) => async (dispatch) => {
  dispatch(resetFilters());
  return await dispatch(
    fetchEvents({
      filters: { type: eventUtils.EVENT_FILTER_KEYS.all, ...apiService.getDefaultSort() },
      pagination: params.pagination,
    }),
  );
};

const isLoadedAll = (state, payload) => {
  return state.ids.length + payload.data.length === payload.metadata.total;
};

const adapter = createEntityAdapter({ selectId: (event) => event.eventId });

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    ...adapter.getInitialState(),
    filters: { type: eventUtils.EVENT_FILTER_KEYS.all, ...apiService.getDefaultSort() },
    pagination: { ...apiService.getDefaultPagination(), loadedAll: false },
    loading: {},
  },
  reducers: {
    reset(state) {
      state.pagination = apiService.getDefaultPagination();
      adapter.removeAll(state);
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    putFilters(state, action) {
      state.filters = { ...action.payload, ...apiService.getDefaultSort() };
    },
    applyFilters(state) {
      adapter.removeAll(state);
      state.pagination = apiService.getDefaultPagination();
    },
    resetFilters(state, action) {
      if (action.payload) {
        const newstate = { ...state.filters };
        delete newstate[action.payload];
        state.filters = newstate;
      } else {
        state.filters = { type: eventUtils.EVENT_FILTER_KEYS.all, ...apiService.getDefaultSort() };
        adapter.removeAll(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading.fetchAll = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        isLoadedAll(state, action.payload)
          ? (state.pagination.loadedAll = true)
          : (state.pagination.page += 1);
        state.pagination.total = action.payload.metadata.total;
        state.loading.fetchAll = false;
        adapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchEvent.pending, (state) => {
        state.loading.fetchOne = true;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.loading.fetchOne = false;
        state.current = action?.payload?.data;
      })
      .addCase(addEvent.pending, (state) => {
        state.loading.add = true;
      })
      .addCase(addEvent.fulfilled, (state) => {
        state.loading.add = false;
      });
  },
});

const {
  reducer,
  actions: { reset, resetFilters, applyFilters, setFilters, putFilters },
} = eventsSlice;
export { setFilters, putFilters };
const generalSelector = (state) => state.events;
export const eventsSelectors = {
  ...adapter.getSelectors(generalSelector),
  loading: createSelector(generalSelector, ({ loading }) => loading),
  pagination: createSelector(generalSelector, ({ pagination }) => pagination),
  filters: createSelector(generalSelector, ({ filters }) => filters),
  filtersType: createSelector(generalSelector, ({ filters }) => filters.type),
  event: createSelector(generalSelector, (events) => events.current),
};

export default reducer;

export const refetchEvents = (params) => async (dispatch) => {
  await dispatch(reset());
  return dispatch(fetchEvents({ ...params, pagination: apiService.getDefaultPagination() }));
};
