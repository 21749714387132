import React, { FC, Ref } from 'react';
import { Modifier } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { InputClassNames } from 'react-day-picker/types/ClassNames';
import { Control, Controller } from 'react-hook-form';
import { Label } from '../Label/Label';
import { GenericControl } from '../GenericControl/GenericControl';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { DatePicker } from './DatePicker';
import './InputDate.css';

interface IProps {
  value: number | undefined;
  classNames?: Partial<InputClassNames>;
  selected?: Modifier | Modifier[];
  disabled?: Modifier | Modifier[];
  months?: number;
  inputRef?: Ref<HTMLInputElement>;
  onDayClick?: () => void;
  onChange: (value: number) => void;
}

export const InputDate = React.forwardRef<DayPickerInput, IProps>(
  ({ value, onChange, months, onDayClick, inputRef, ...rest }, ref) => (
    <DatePicker
      ref={ref}
      value={value}
      months={months}
      inputRef={inputRef}
      onChange={onChange}
      onDayClick={onDayClick}
      {...rest}
    />
  ),
);
InputDate.displayName = 'InputDate';

interface IInputDateControlProps {
  label: string;
  name: string;
  control: Control;
  required: boolean;
  error: Error | string;
}

export const InputDateControl: FC<IInputDateControlProps> = ({
  label,
  name,
  control,
  required,
  error,
}) => {
  return (
    <GenericControl>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <DatePicker value={value} onChange={onChange} />
        )}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </GenericControl>
  );
};
