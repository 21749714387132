import { useEffect, useRef, useState } from 'react';

export const useScrollObserver = (
  callback: () => void,
): React.Dispatch<React.SetStateAction<Element | null>> => {
  const [targetElement, setTargetElement] = useState<Element | null>(null);

  const prevY = useRef(0);

  const options = {
    root: null,
    rootMargin: '100px',
    threshold: 1.0,
  };

  const handleObserver: IntersectionObserverCallback = (entities) => {
    const y = entities[0].boundingClientRect.y;

    if (prevY.current > y) {
      callback();
    }

    prevY.current = y;
  };

  const observer = useRef(new IntersectionObserver(handleObserver, options));

  useEffect(() => {
    if (targetElement) {
      observer.current.observe(targetElement);
    }
  }, [targetElement]);

  return setTargetElement;
};
