import React from 'react';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import './ListItems.css';

export const ListItems = ({ className = '', children, ...rest }) => {
  return (
    <List className={classNames('cmp-list-items', className)} {...rest}>
      {children}
    </List>
  );
};
