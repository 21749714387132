import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, buttonVariants, RoundMarker, Text } from 'shared/components';
import './SetPasswordSwitch.css';

export const SetPasswordSwitch = ({ isActive, onChange }) => {
  const { t } = useTranslation();
  return (
    <Button variant={buttonVariants.TEXT} onClick={onChange}>
      <Box flexX>
        <RoundMarker isActive={isActive} />
        <Text className="set-password-switch__text">
          {t('ACCOUNT_STRINGS.form.change_password')}
        </Text>
      </Box>
    </Button>
  );
};
