import React from 'react';
import classNames from 'classnames';
import './RoundMarker.css';

export const RoundMarker = ({ isActive, size = 24 }) => {
  return (
    <div
      style={{ height: size, width: size }}
      className={classNames('cmp-round-marker', { 'cmp-round-marker--active': isActive })}
    ></div>
  );
};
