import { createApi } from '@reduxjs/toolkit/query/react';
import { apiService } from 'shared/service';
import { TOPICS_MODULE_KEY } from '../utils';
import { IComment, CreateTopicModel, ITopic } from '../types';
import {
  FetchEntityResponse,
  FetchListResponse,
  IFetchListArgs,
} from 'shared/service/common-entity-logic/types';

export const topicsApi = createApi({
  reducerPath: 'topicsNew',
  baseQuery: apiService.rtkFetch,
  // refetchOnMountOrArgChange: true,
  tagTypes: ['Topics', 'Comment'],
  endpoints: (builder) => ({
    fetchTopics: builder.query<FetchListResponse<ITopic>, IFetchListArgs>({
      query: (args) => `${TOPICS_MODULE_KEY}?${apiService.rtkGetUrlParams(args)}`,
      transformResponse: (value: FetchListResponse<ITopic>) => {
        return value;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ topicId }) => ({ type: 'Topics' as const, id: topicId })),
              { type: 'Topics', id: 'LIST' },
            ]
          : [{ type: 'Topics', id: 'LIST' }],
    }),
    fetchTopic: builder.query<FetchEntityResponse<ITopic>, string>({
      query: (id) => `${TOPICS_MODULE_KEY}/${id}`,
    }),
    createTopic: builder.mutation<ITopic, CreateTopicModel>({
      query: (model) => ({ url: `${TOPICS_MODULE_KEY}`, method: 'POST', body: model }),
      invalidatesTags: [{ type: 'Topics', id: 'LIST' }],
    }),
    fetchComments: builder.query<FetchListResponse<IComment>, IFetchListArgs & { topicId: string }>(
      {
        query: ({ topicId, ...fetchListParams }) =>
          `${TOPICS_MODULE_KEY}/${topicId}/comments?${apiService.rtkGetUrlParams(fetchListParams)}`,
        providesTags: ['Comment'],
      },
    ),
    createComment: builder.mutation<IComment, Omit<IComment, 'id'> & { topicId: string }>({
      query: ({ topicId, ...model }) => ({
        url: `${TOPICS_MODULE_KEY}/${topicId}/comments`,
        method: 'POST',
        body: model,
      }),
      invalidatesTags: ['Comment'],
    }),
  }),
});

export const {
  useFetchTopicsQuery,
  endpoints: {
    fetchTopics: { useQueryState: useFetchTopicsQueryState },
    fetchComments: { useQueryState: useFetchCommentsQueryState },
  },
  useFetchTopicQuery,
  useCreateTopicMutation,
  useFetchCommentsQuery,
  useCreateCommentMutation,
} = topicsApi;
