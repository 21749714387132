import React, { useEffect, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { BackRouting, Box, Text, ModuleContent, LoadingWrapper } from 'shared/components';
import { useParams } from 'react-router-dom';
import { isQuorumReached } from '../../utils';
import { useFetchPollQuery } from '../../redux';
import { ResultsList } from './results-list/ResultsList';
import { PollMainInfo } from '../main-info/PollMainInfo';
import './PollResults.css';

export const PollResults: VFC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: pollResponse, isFetching: loading } = useFetchPollQuery(id);
  const { t } = useTranslation();

  if (!pollResponse || !pollResponse.data) {
    return null;
  }

  const { title, description, questions, activeUntil } = pollResponse.data;

  return (
    <LoadingWrapper isLoading={loading}>
      <ModuleContent>
        <Box className="poll-results" flexY gapDouble>
          <Box>
            <BackRouting text={t('POLLS_STRINGS.poll.back')} />
          </Box>

          <Box flexY>
            <Text size={Text.sizes.xxxl} bold>
              {title}
            </Text>
            <Text size={Text.sizes.l} thin multiline>
              {description}
            </Text>
          </Box>

          <PollMainInfo poll={pollResponse.data} />
          {isQuorumReached(questions) && (
            <ResultsList questions={questions} finishDate={activeUntil} />
          )}
        </Box>
      </ModuleContent>
    </LoadingWrapper>
  );
};
