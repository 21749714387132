import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

export const ChatSearchFilter: FC<IProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TextField
      size="small"
      fullWidth
      className="text-field"
      value={value}
      name="searchText"
      onChange={(event) => onChange(event.target.value)}
      placeholder={t('MESSAGES_STRINGS.search')}
      variant="outlined"
      color="primary"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
