import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardMainTitle, CardSection, Text } from 'shared/components';
import { ParticipationStatus } from '../../../types/types';
import './Participate.css';

interface IOptionProps<T> {
  text: string;
  value: T;
  onChange: (value: T | null) => unknown;
}

const Option = <T extends string | number | readonly string[] | undefined>({
  text,
  value,
  onChange,
}: IOptionProps<T>) => (
  <label className="participate__option">
    <input
      className="participate__input"
      name="participate"
      type="radio"
      value={value}
      onChange={() => onChange(value === '' ? null : value)}
    />
    <Text>{text}</Text>
  </label>
);

interface IProps {
  onChange: any;
}

export const Participate: FC<IProps> = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardMainTitle>{t('POLLS_STRINGS.quorum')}</CardMainTitle>
      <CardSection>
        <Text size={Text.sizes.xl} thin className="participate__title">
          {t('POLLS_STRINGS.poll.askToParticipate')}
        </Text>
      </CardSection>

      <Box className="participate__options" flexY>
        <Option
          text={t('POLLS_STRINGS.poll.participation.yes')}
          value={ParticipationStatus.yes}
          onChange={onChange}
        />
        <Option
          text={t('POLLS_STRINGS.poll.participation.no')}
          value={ParticipationStatus.no}
          onChange={onChange}
        />
        <Option
          text={t('POLLS_STRINGS.poll.participation.skip')}
          value={ParticipationStatus.skip}
          onChange={onChange}
        />
      </Box>
    </Card>
  );
};
