import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullname } from 'modules/accounts';
import {
  CardSection,
  ListItemCard,
  RenderTextField,
  Text,
  Box,
  CardMainTitle,
} from 'shared/components';
import { RenderFieldLabel } from 'shared/components/field-renderers/RenderFieldLabel';
import { renderDate } from 'shared/service/locales';
import { ITopic } from 'modules/topics/types';
import './TopicCard.css';
interface IProps {
  topic: ITopic;
  onClick: (topic: ITopic) => void;
}

export const TopicCard: FC<IProps> = ({ topic, onClick }) => {
  const { t } = useTranslation();

  return (
    <ListItemCard className="topic-card" onClick={() => onClick(topic)}>
      <CardSection>
        <CardMainTitle>{topic.title}</CardMainTitle>
        <Box flexX gap>
          <RenderFieldLabel>{t('TOPICS_STRINGS.topic.comments')}</RenderFieldLabel>
          <Text>{topic.commentsCount}</Text>
        </Box>
      </CardSection>
      <CardSection flexX className="topic-card__info">
        <RenderTextField label={t('TOPICS_STRINGS.topic.author')}>
          {getFullname(topic.author)}
        </RenderTextField>
        <RenderTextField label={t('TOPICS_STRINGS.topic.lastCommentedAt')}>
          {topic.commentsCount && topic.lastCommentedAt ? renderDate(topic.lastCommentedAt) : '--'}
        </RenderTextField>
      </CardSection>
    </ListItemCard>
  );
};
