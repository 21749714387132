import { createApi } from '@reduxjs/toolkit/query/react';
import { apiService } from 'shared/service';
import { login } from 'modules/auth';

interface IAccountDto {
  id: string;
}

interface IInitDto {
  notifications: unknown[];
  userData: {
    displayName: string;
    email: string;
  };
}

interface IUpdatePasswordArgs {
  email: string;
  password: string;
  oldPassword: string;
}

interface IUpdatePasswordResult {
  userId: string;
}

export const accountApi = createApi({
  reducerPath: 'account',
  baseQuery: apiService.rtkFetch,
  endpoints: (builder) => ({
    getMyAccount: builder.query<IAccountDto, void>({
      query: () => `users/mydata`,
      transformResponse: (response: { data: IAccountDto }) => response.data,
    }),
    getInitData: builder.query<IInitDto, void>({
      query: () => `init`,
    }),
    updateMyAccount: builder.mutation({
      query: (body) => ({
        url: `users/mydata`,
        method: 'PUT',
        body,
      }),
    }),
    setPassword: builder.mutation<IUpdatePasswordResult, IUpdatePasswordArgs>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        await login({ password: arg.oldPassword, username: arg.email });
        const updatePasswordResult = ((await baseQuery({
          url: `users/mydata/setpwd`,
          method: 'PATCH',
          body: { password: arg.password },
        })) as unknown) as { data: IUpdatePasswordResult };
        return { data: { userId: updatePasswordResult.data.userId } };
      },
    }),
  }),
});

export const {
  useGetMyAccountQuery,
  useUpdateMyAccountMutation,
  useSetPasswordMutation,
  useGetInitDataQuery,
} = accountApi;
