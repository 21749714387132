import { io } from 'socket.io-client';

import { config } from 'config/config';
import { LocalStorage } from '../local-storage';

class Socket {
  constructor(url) {
    this.url = url;
    this.socket = null;
  }

  connect() {
    const token = LocalStorage.token.get();
    this.close();
    this.socket = io(this.url, {
      extraHeaders: { Authorization: `Bearer ${token}` },
      reconnectionAttempts: 10,
    });
  }

  open() {
    if (this.socket) {
      this.socket.open();
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  emit(event, payload) {
    if (this.socket) {
      this.socket.emit(event, payload);
    }
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }
}

export const socket = new Socket(config.api.socketUrl);
