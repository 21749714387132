import { IAnswer, IQuestion } from '../types/models';

export const URGENT_THRESHOLD = 60 * 60 * 24;

export const isDecisionMade = (question: IQuestion): boolean => {
  return question.answers.some((answer: IAnswer) => answer.result > 50);
};

export const isQuorumReached = (questions: IQuestion[]): boolean => {
  // TODO temporary solution, need to use 'quorumPercentage'
  const answers: any = questions.map((el: IQuestion) => el.answers);
  const concatAnswers: IAnswer[] = [].concat(...answers);
  const voiceCount = concatAnswers.reduce((curr: number, prev: IAnswer) => curr + prev.result, 0);

  return voiceCount >= 1;
  // return Math.random() > 0.5;
};

export const isPollFinish = (expirationDate: number): boolean => {
  const currentDate: number = Date.now();
  const activeUntilDate: number = expirationDate * 1000;

  return currentDate > activeUntilDate;
};

export const isPollUrgent = (expirationTimestamp: number): boolean => {
  const currentTimestamp = Date.now() / 1000;

  return (
    currentTimestamp > expirationTimestamp &&
    currentTimestamp - expirationTimestamp < URGENT_THRESHOLD
  );
};
