import { ReactComponent as Main } from 'assets/icons/main.svg';
import { ReactComponent as Topics } from 'assets/icons/topics.svg';
import { ReactComponent as Polls } from 'assets/icons/polls.svg';
import { ReactComponent as Events } from 'assets/icons/events.svg';
import { ReactComponent as News } from 'assets/icons/news.svg';
import { ReactComponent as Documents } from 'assets/icons/documents.svg';

export const MenuIcons = {
  Main,
  Topics,
  Polls,
  Events,
  News,
  Documents,
};
