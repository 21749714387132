import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionButton, BottomSticky, Box } from 'shared/components';
import { ScrollDetector } from 'shared/hooks/scroll/ScrollDetector';
import { SortKey } from 'shared/hooks/sorting';
import { TopicsViewPage } from './TopicsPage';
import { ITopicFilters } from '../../types';

interface IProps {
  pagination: { page: number; limit: number; total: number; setNextPage: () => void };
  filters: ITopicFilters;
  sort: SortKey;
}

export const TopicsViewPages: FC<IProps> = ({
  pagination: { page, limit, total, setNextPage },
  filters,
  sort,
}) => {
  const { t } = useTranslation();
  const pageNumberList = new Array(page + 1 + 1).fill(null).map((i, index) => index);

  const { url } = useRouteMatch();

  return (
    <Box>
      {pageNumberList.map((pageNumber) => (
        <TopicsViewPage
          key={pageNumber}
          page={pageNumber}
          limit={limit}
          filters={filters}
          sort={sort}
        />
      ))}

      {total / (page + 1 + 1) > limit && <ScrollDetector onDetect={setNextPage} />}
      <BottomSticky>
        <div className="topics-view__button">
          <ActionButton to={`${url}/new`} fullWidth>
            {t('TOPICS_STRINGS.create_topic')}
          </ActionButton>
        </div>
      </BottomSticky>
    </Box>
  );
};
