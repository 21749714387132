import React from 'react';
import { useController } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import { default as MuiSelect } from '@material-ui/core/Select';
import { Label } from 'shared/components/form-controls/Label/Label';

export const Select = ({ fullWidth, label, name, control, options }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  return (
    <FormControl variant="outlined" className={''} fullWidth={fullWidth}>
      {label && <Label>{label}</Label>}
      <MuiSelect
        inputProps={{
          name: 'age',
          id: 'filled-age-native-simple',
        }}
        margin="dense"
        value={value}
        onChange={handleChange}
      >
        <option aria-label="None" value="" />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
