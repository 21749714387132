import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, buttonVariants, Text } from 'shared/components';
import { Select } from 'shared/components/form-controls/Select/Select';
import { apiService } from 'shared/service';
import './TopicSelector.css';

export const TopicSelector = ({ name, control }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);

  const handleActivate = async () => {
    try {
      setLoading(true);
      const { data: topicsData } = await apiService.read('topics', {
        pagination: { limit: 1000, page: 0 },
      });
      setTopics(topicsData);
      setActive(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="topic-selector">
      {loading && t('EVENTS_STRINGS.loading')}
      {!active ? (
        <Button fullWidth onClick={handleActivate} variant={buttonVariants.OUTLINED}>
          <Text>{t('EVENTS_STRINGS.form.topic')}</Text>
        </Button>
      ) : (
        <Select
          control={control}
          name="topicId"
          fullWidth
          options={topics.map((topic) => ({ value: topic.topicId, label: topic.title }))}
        />
      )}
    </Box>
  );
};
