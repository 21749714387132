import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button, Card, CardSection, ListItems, Text } from 'shared/components';
import './SignApprovalModal.css';

export const SignApprovalModal = ({ formData, isOpen, onApprove, onDecline }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <Card className="sign-modal">
        <CardSection>
          <p>
            <Text size={Text.sizes.xxl}>{t('POLLS_STRINGS.poll.signingTitle')}</Text>
          </p>
          <p>
            <Text size={Text.sizes.l} thin>
              {t('POLLS_STRINGS.poll.signingAlert')}
            </Text>
          </p>
        </CardSection>
        <ListItems>
          {formData.map((question) => (
            <Box key={question.questionId} className="selected-item-card">
              <Box className="poll-question">{question.text}</Box>
              <Box>
                {t('POLLS_STRINGS.poll.yourSelection')} :{' '}
                {question.answers.find((answer) => answer.isChosen)?.text}
              </Box>
            </Box>
          ))}
        </ListItems>
        <CardSection flexY gap>
          <Button onClick={onApprove}>{t('POLLS_STRINGS.poll.sign')}</Button>
          <Button onClick={onDecline}>{t('POLLS_STRINGS.poll.cancel')}</Button>
        </CardSection>
      </Card>
    </Dialog>
  );
};
