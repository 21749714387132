import React, { ReactNode, ReactElement } from 'react';

import { socket } from 'shared/service/socket';
import { SocketContext } from './SocketContext';
import { useSocket } from './useSocket';

interface IProps {
  children: ReactNode;
}

export const SocketProvider = ({ children }: IProps): ReactElement => {
  const ws = useSocket(socket);

  return <SocketContext.Provider value={ws}>{children}</SocketContext.Provider>;
};
