import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardMainTitle, CardSection, ListItemCard, RenderTextField, Tag } from 'shared/components';
import { getFullname } from 'modules/accounts';
import { eventUtils } from 'modules/events/utils';
import { dateUtils } from 'shared';

export const EventCard = ({ event }) => {
  const { t } = useTranslation();
  return (
    <ListItemCard>
      <CardSection flexX spaceBetween>
        <CardMainTitle>{event.title}</CardMainTitle>
        {eventUtils.isEnded(event) && <Tag>{t('EVENTS_STRINGS.event.is_ended')}</Tag>}
      </CardSection>
      {event.isOfficial && <Tag>{t('EVENTS_STRINGS.type.official')}</Tag>}
      <CardSection flexX>
        <RenderTextField label={t('EVENTS_STRINGS.event.author')}>
          {getFullname(event.author)}
        </RenderTextField>
        <RenderTextField label={t('EVENTS_STRINGS.event.date')}>
          {dateUtils.getDisplayTime(event.startsAt)}
        </RenderTextField>
      </CardSection>
    </ListItemCard>
  );
};
