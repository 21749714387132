import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BottomSticky, Box, ActionButton, ModuleContent } from 'shared/components';
import { eventsSelectors } from '../../redux';
import { EventsList } from '../list/EventsList';
import { EventFilters } from '../filter/EventFilters';
import { TagFilters } from '../filter/TagFilters';
import { EventsViewHeader } from '../events-view-header/EventsViewHeader';

export const EventsView = () => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  let { url } = useRouteMatch();

  const loading = useSelector(eventsSelectors.loading)?.fetchAll;
  const eventsData = useSelector(eventsSelectors.selectAll);

  return (
    <ModuleContent>
      <Box flexY gap className="polls-header-block">
        <EventsViewHeader onFilterClick={() => setShowFilters(!showFilters)} />
        {showFilters && (
          <Box>
            <EventFilters />
          </Box>
        )}
        <TagFilters />
      </Box>

      <Box>
        <EventsList events={eventsData} loading={loading} />
        <BottomSticky>
          <div className="polls-view__button">
            <ActionButton to={`${url}/new`} fullWidth>
              {t('EVENTS_STRINGS.create_event')}
            </ActionButton>
          </div>
        </BottomSticky>
      </Box>
    </ModuleContent>
  );
};
