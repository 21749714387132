import { PollListTags } from '../utils/constants';

const POLLS_STRINGS_EST = {
  polls: 'Polls',
  create_poll: 'Create a poll',
  filter: {
    header: '',
  },
  loading: 'Loading polls...',
  polls_count: 'There are {{pollsCount}} polls opened',
  poll: {
    createdAt: 'Created: ',
    activeUntil: 'Active until: ',
    signingTitle: 'Sign your vote',
    signingAlert: 'Please make sure that the answers are correct.',
    sign: 'Sign',
    yourSelection: 'Your choice',
    yourVoted: 'You upvoted',
    back: 'Back',
    ended: 'Ended',
    success: 'The poll was conducted',
    failed: "The poll wasn't conducted",
    askToParticipate: 'Would you like to participate?',
    chairman: 'Chairman',
    vote: 'Vote',
    participation: {
      yes: 'I will participate',
      no: 'I will not participate',
      skip: 'I abstain',
    },
    isParticipated: 'You have made your choice',
    notParticipated: 'Your vote is missing',
    discuss: 'Discuss the poll',
    status: {
      label: 'status',
      urgent: 'Urgent',
      active: 'Active',
    },
    voting: 'Voting',
    cancel: 'Cancel',
  },
  form: {
    header: 'Create a poll',
    publish: 'Publish the poll',
    title: 'Title',
    description: 'Description',
    setActiveUntil: 'Set due date',
    questions: 'Questionaire',
    addQuestion: 'Add a question',
    deleteQuestion: 'Remove the question',
    answers: 'Answers',
    addAnswer: 'Add an answer',
  },
  tags: {
    [PollListTags.ACTIVE]: 'Active',
    [PollListTags.ALL]: 'All',
    [PollListTags.URGENT]: 'Urgent',
    [PollListTags.CLOSED]: 'Closed',
    [PollListTags.COMPLETED]: 'Completed',
  },
  quorum: 'Quorum',
};

export default POLLS_STRINGS_EST;
