import { useState } from 'react';

export type EntityFilters = Record<string, unknown>;

export type EntityFiltersHookModel<T extends EntityFilters> = {
  filters: T;
  resetFilters: (filters: T) => void;
  set: (name: string, value: unknown) => void;
};

export const useEntityFilters = <T extends EntityFilters>(): EntityFiltersHookModel<T> => {
  const [filters, setFilters] = useState<T>({} as T);

  const resetFilters = (filters: T): void => {
    setFilters(filters);
  };

  const set = (name: string, value: unknown): void => {
    setFilters({ ...filters, [name]: value });
  };

  return { filters, resetFilters, set };
};
