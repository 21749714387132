import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import ListItemMui, { ListItemProps } from '@material-ui/core/ListItem';

interface IProps extends ListItemProps {
  className?: string;
  onClick?: () => void;
  button?: boolean | any;
}

export const ListItem: PropsWithChildren<FC<IProps>> = ({ children, className, ...props }) => (
  <ListItemMui className={classNames('cmp-list-item', className)} {...props}>
    {children}
  </ListItemMui>
);
