import { Tags } from '../utils/constants';

const TOPICS_STRINGS_EST = {
  topics: 'Topics',
  topics_count: 'There are {{topicsCount}} discussions',
  tags: {
    [Tags.active]: 'Active',
    [Tags.all]: 'All',
    [Tags.urgent]: 'With a due date',
    [Tags.new]: 'New',
    [Tags.closed]: 'Closed',
  },
  create_topic: 'Start a discussion',
  filter: {
    header: 'Title',
    setFromDate: 'Last updated from',
    setToDate: 'Last updated to',
    apply: 'Apply filters',
    reset: 'Reset filters',
  },
  form: {
    header: 'Start a discussion',
    title: 'Title',
    description: 'Text',
    activeUntil: 'Due date',
    setActiveUntil: 'Set a due date',
    changeActiveUntil: 'Change the due date',
    publish: 'Publish the topic',
  },
  topic: {
    author: 'Author',
    back: 'Back to topic list',
    messageCounter: '{{count}} message(-s)',
    created: 'Created',
    comments: 'Comments',
    inputPlaceholder: 'Say something',
    lastCommentedAt: 'Last comment',
    loading: 'Loading',
  },
};

export default TOPICS_STRINGS_EST;
