import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CompletedIcon } from 'assets/icons/completed.svg';
import { ReactComponent as UrgentIcon } from 'assets/icons/urgent-poll.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Box, CardMainTitle, IconTag, ListItemCard, RenderTextField } from 'shared/components';
import { renderDate } from 'shared/service/locales';
import { getFullname } from 'modules/accounts';
import { isPollUrgent } from '../../utils';
import { IPoll } from '../../types/models';
import './PollCard.css';

interface IProps {
  poll: IPoll;
  onClick: (poll: IPoll) => unknown;
}

export const PollCard: FC<IProps> = ({ poll, onClick }) => {
  const { t } = useTranslation();
  return (
    <ListItemCard className="poll-card" onClick={() => onClick(poll)}>
      <Box className="poll-card__title-icons">
        <Box flexX className="poll-card__icons">
          {isPollUrgent(poll.activeUntil) && <UrgentIcon />}
          {poll?.isParticipated ? (
            <IconTag className="poll-card__icons__completed">
              <CompletedIcon />
            </IconTag>
          ) : (
            <IconTag className="poll-card__icons__not-completed">
              <InfoIcon />
            </IconTag>
          )}
        </Box>
        <CardMainTitle>{poll.title}</CardMainTitle>
      </Box>
      <Box flexX className="poll-card__chair-expiration">
        <RenderTextField label={t('POLLS_STRINGS.poll.chairman')}>
          {getFullname(poll.author)}
        </RenderTextField>
        <RenderTextField label={t('POLLS_STRINGS.poll.activeUntil')}>
          {renderDate(poll.activeUntil)}
        </RenderTextField>
      </Box>
    </ListItemCard>
  );
};
