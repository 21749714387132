import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Box } from 'shared/components';
import { isDecisionMade, isPollFinish } from '../../../utils';
import { AnswerResult } from '../answer-result/AnswerResult';
import { IQuestion } from '../../../types/models';
import './ResultItem.css';

interface IProps {
  question: IQuestion;
  questionTitle: ReactElement;
  finishDate?: number;
}

export const ResultItem: FC<IProps> = ({ question, questionTitle: Title, finishDate }) => {
  const sumOfResults = question.answers.reduce((curr, prev) => {
    return curr + prev.result;
  }, 0);
  return (
    <Box
      className={classNames('result-item-card', {
        success: isDecisionMade(question) && finishDate && isPollFinish(finishDate),
        failed: !isDecisionMade(question) && finishDate && isPollFinish(finishDate),
      })}
    >
      {Title}
      {question.answers.map((answer, index) => (
        <AnswerResult
          key={answer.answerId}
          value={!sumOfResults ? null : answer.result / sumOfResults}
          text={answer.text}
          even={index % 2 === 0}
          className={classNames({ 'result-item__answer--my-selected': answer?.isChosen })}
        />
      ))}
    </Box>
  );
};
