import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { IoClose, IoMenu } from 'react-icons/io5';
import { NAVIGATION_OPTIONS } from '../../constants/navigationOptions';
import NavigationItem from '../NavigationItem/NavigationItem';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { Box } from 'shared/components';
import { Logo } from 'pages/app-root/components/Logo/Logo';
import './Navigation.css';

const NavigationControl = ({ icon, onToggle }) => {
  return (
    <Box flexX>
      <Link to="#" className="navigation__header__icon" onClick={onToggle}>
        {icon}
      </Link>
      <Logo className="navigation__header--dark navigation__header__logo" />
    </Box>
  );
};

export const Navigation = () => {
  const [isNavOpen, setNavigation] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavigation(false);
  }, [location.pathname]);

  const showNavigation = () => setNavigation(!isNavOpen);

  return (
    <Box flexX className="navigation">
      <NavigationControl icon={<IoMenu />} onToggle={showNavigation} />
      <nav className={classNames('navigation__list', { active: isNavOpen })}>
        <ul className="navigation__items">
          <Box className="navigation__header">
            <NavigationControl icon={<IoClose />} onToggle={showNavigation} />
          </Box>
          {NAVIGATION_OPTIONS.map((option, index) => (
            <li key={index}>
              <NavigationItem option={option} />
            </li>
          ))}
        </ul>
        <Box flexY gapDouble>
          <LanguageSelect />
        </Box>
      </nav>
    </Box>
  );
};
