const MESSAGES_STRINGS_EST = {
  title: 'Messages',
  noMessages: 'You have no chats with criteria specified',
  writeToNeighbor: 'You can create a new chat to write your neighbor',
  ok: 'OK',
  createChat: '+ New Chat',
  newChat: 'New Chat',
  send: 'Send',
  sendMessageToLabel: 'Send message to',
  subjectLabel: 'Subject',
  messageLabel: 'Message',
  search: 'Search',
  typeMessagePlaceholder: 'Type a message',
  selectPersonPlaceholder: 'Select a person',
  errors: {
    requiredField: 'Field is required',
    lengthLess50: 'Field length should be not more than 50 symbols',
  },
};

export default MESSAGES_STRINGS_EST;
