import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Card, CardSection, Text } from 'shared/components';
import { FiltersButtons, FiltersDates, DateFilterParams } from 'shared/components/module-blocks';
import { ITopicFilters } from '../../types';
import './TopicFilters.css';

interface IProps {
  filters?: ITopicFilters;
  loading?: boolean;
  onChange: (filters: ITopicFilters) => void;
  onReset: () => void;
}

export const TopicFilters: FC<IProps> = ({ filters = {}, loading, onChange, onReset }) => {
  const { t } = useTranslation();
  const [localFilters, setLocalFilters] = useState<ITopicFilters>(filters);
  const { filterHeaderText, filterFromDate, filterToDate } = localFilters;

  const handleFilterDateChange = ({ from, to }: DateFilterParams) => {
    const newDateFilters: { filterFromDate?: number; filterToDate?: number } = {};
    if (from) {
      newDateFilters.filterFromDate = from;
    }
    if (to) {
      newDateFilters.filterToDate = to;
    }
    setLocalFilters({ ...localFilters, ...newDateFilters });
  };

  const handleApply = () => {
    onChange(localFilters);
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <Card className="topic-filters" onClick={null}>
      <CardSection>
        <Text size={Text.sizes.xl}>{t('SHARED_STRINGS.filters.__title')}</Text>
      </CardSection>
      <Input
        placeholder={t('TOPICS_STRINGS.filter.header')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setLocalFilters({ filterHeaderText: event.currentTarget.value });
        }}
        onKeyEnter={handleApply}
        value={filterHeaderText || ''}
        label={t('SHARED_STRINGS.search')}
      />
      <FiltersDates from={filterFromDate} to={filterToDate} onChange={handleFilterDateChange} />
      <FiltersButtons loading={loading} onReset={onReset} onApply={handleApply} />
    </Card>
  );
};
