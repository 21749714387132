import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoFilterOutline } from 'react-icons/io5';
import { Box, Button, Text } from 'shared/components';

export const EventsViewHeader = ({ filterActive, onFilterClick }) => {
  const { t } = useTranslation();
  return (
    <Box flexX>
      <Text size={Text.sizes.xxxl} t>
        {t('EVENTS_STRINGS.events')}
      </Text>
      <Box>
        <Button icon={<IoFilterOutline />} onClick={onFilterClick}></Button>
      </Box>
    </Box>
  );
};
