export interface IFontSizeMap {
  [key: string]: number;
}

export const fontSizeMap: IFontSizeMap = {
  1: 11,
  2: 10,
  3: 8,
} as const;

export const MAX_NOTIFICATIONS_TO_DISPLAY = 99;
