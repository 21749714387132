import React, { ChangeEvent, FC, FormEvent, ComponentType, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'ramda';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { Box, Textarea } from 'shared/components';
import { useParticipants } from './useParticipants';
import { validate } from './validation';
import { ICreateChatEvent } from '../../models/event.model';
import { IChatParticipant } from '../../models/chat.model';
import './CreateChatModal.scss';

interface IForm {
  header: string;
  msg: string;
}

interface IFormError {
  message: string;
  path: string;
}

const initialForm: IForm = { header: '', msg: '' };

interface IProps {
  isOpen: boolean;
  onApprove: (payload: ICreateChatEvent) => void;
  onDecline: () => void;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}) as ComponentType<TransitionProps & { children?: ReactElement }>;

export const CreateChatModal: FC<IProps> = ({ isOpen, onApprove, onDecline }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedParticipant, setParticipant] = useState<IChatParticipant | null>(null);
  const [formValue, setFormValue] = useState<IForm>(initialForm);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { participants, isLoading, fetchParticipants } = useParticipants();

  const resetForm = () => {
    setErrors({});
    setFormValue(initialForm);
    setParticipant(null);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const header: string | undefined = selectedParticipant?.correspondentDisplayName;
    const form = {
      person: selectedParticipant,
      ...formValue,
      header,
    };
    try {
      await validate(form);
      onApprove({
        chatStartedAt: Date.now(),
        ...formValue,
        ...(selectedParticipant ? selectedParticipant : {}),
        header,
      });
      resetForm();
    } catch (error: any) {
      if (Array.isArray(error.inner)) {
        const errors: Record<string, string> = error.inner.reduce(
          (acc: Record<string, string>, err: IFormError) => ({ ...acc, [err.path]: err.message }),
          {},
        );
        setErrors(errors);
      }
    }
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors(
      value
        ? omit([name], errors)
        : { ...errors, [name]: t('MESSAGES_STRINGS.errors.requiredField') },
    );
  };

  const onSelect = (event: ChangeEvent<Record<string, never>>, option: IChatParticipant | null) => {
    setErrors(omit(['person'], errors));
    setParticipant(option);
  };

  const onDeclineForm = () => {
    resetForm();
    onDecline();
  };

  const onChangeRecipient = (event: ChangeEvent<Record<string, never>>, value: string) => {
    if (!event) {
      setParticipant(null);
      return;
    }
    if (selectedParticipant) {
      setParticipant(null);
    }
    fetchParticipants(value);
  };

  return (
    <div className="chat-dialog-container">
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDeclineForm}
        maxWidth="lg"
        fullWidth
        aria-labelledby="New chat dialog"
        aria-describedby="create a new chat and get started a conversation"
      >
        <DialogTitle className="chat-dialog-container__title">
          <div>{t('MESSAGES_STRINGS.newChat')}</div>
          <IconButton aria-label="close" onClick={onDeclineForm}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form
            className="chat-dialog-container__create-chat-form"
            onSubmit={onSubmit}
            noValidate
            autoComplete="off"
          >
            <Box flexY>
              <label className="chat-dialog-container__field-label">
                {t('MESSAGES_STRINGS.sendMessageToLabel')}
              </label>
              <Autocomplete
                className="chat-dialog-container__autocomplete"
                value={selectedParticipant}
                open={open}
                onChange={onSelect}
                onInputChange={onChangeRecipient}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionSelected={(option, value) =>
                  option.correspondentDisplayName === value.correspondentDisplayName
                }
                getOptionLabel={(option) => option.correspondentDisplayName || ''}
                options={participants}
                loading={isLoading}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    placeholder={t('MESSAGES_STRINGS.selectPersonPlaceholder')}
                    error={Boolean(errors.person)}
                    helperText={errors.person}
                  />
                )}
              />
            </Box>

            <Box flexY>
              <Textarea
                // @ts-ignore
                label={t('MESSAGES_STRINGS.messageLabel')}
                value={formValue.msg}
                name="msg"
                onChange={onChangeInput}
                placeholder={t('MESSAGES_STRINGS.typeMessagePlaceholder')}
                error={Boolean(errors.msg)}
                helperText={errors.msg}
              />
            </Box>

            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="chat-dialog-container__send-button"
                size="medium"
                startIcon={<SendIcon />}
              >
                {t('MESSAGES_STRINGS.send')}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
