import React, { useState, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import {
  Box,
  Button,
  buttonVariants,
  DatePicker,
  FormHeader,
  Input,
  Text,
  Textarea,
  ActionButton,
  ModuleContent,
} from 'shared/components';
import { ROUTES } from 'shared/constants';
import { renderDate } from 'shared/service/locales';
import { CreateTopicModel } from 'modules/topics/types';
import { useCreateTopicMutation } from '../../../redux';
import './TopicGenericForm.css';

export const TopicForm: VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeUntilControl, setActiveUntilControl] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' });

  const [createTopic, { isLoading: loading }] = useCreateTopicMutation();

  const handleDayClick = () => {
    setActiveUntilControl(false);
  };

  const handleClearDeadline = () => {
    setActiveUntilControl(false);
    setValue('activeUntil', null);
  };

  const onSubmit = async (data: CreateTopicModel) => {
    const result = await createTopic(data);
    if ('data' in result) {
      history.push(ROUTES.topics);
    }
  };

  const activeUntil = useWatch({ control, name: 'activeUntil' });

  return (
    <ModuleContent light>
      <form className="topic-form" onSubmit={handleSubmit(onSubmit)}>
        <FormHeader text={t('TOPICS_STRINGS.form.header')} closeTo={ROUTES.topics} />
        <Input
          {...register('title', { required: true, maxLength: 150 })}
          autoFocus
          label={t('TOPICS_STRINGS.form.title')}
          error={errors?.title?.message || errors?.title?.type}
        />
        <Textarea
          rows={6}
          {...register('description', { required: true })}
          label={t('TOPICS_STRINGS.form.description')}
          error={errors?.description?.message || errors?.description?.type}
        />
        {activeUntil && (
          <Box flexX>
            <Text>{renderDate(activeUntil)}</Text>
            <Button icon={<IoClose />} onClick={handleClearDeadline}></Button>
          </Box>
        )}

        <Button onClick={() => setActiveUntilControl(true)} variant={buttonVariants.OUTLINED}>
          {activeUntil
            ? t('TOPICS_STRINGS.form.changeActiveUntil')
            : t('TOPICS_STRINGS.form.setActiveUntil')}
        </Button>
        {activeUntilControl && (
          <DatePicker control={control} name="activeUntil" onChange={handleDayClick} />
        )}
        <ActionButton type="submit" disabled={!isDirty || !isValid || loading}>
          {t('TOPICS_STRINGS.form.publish')}
          {loading && 'loading'}
        </ActionButton>
      </form>
    </ModuleContent>
  );
};
