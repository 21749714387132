import React, { FC } from 'react';
import { Avatar, BackRouting, Box, Text } from 'shared/components';
import './ChatHeader.scss';

interface IProps {
  title?: string;
  imageSrc?: string;
  isActive?: boolean;
}

export const ChatHeader: FC<IProps> = ({ title, imageSrc = '' }) => {
  return (
    <Box className="chat-header" flexX>
      <BackRouting />
      <Avatar>{imageSrc}</Avatar>
      <Box className="chat-header__right">
        <Text
          className="chat-header__title"
          size={Text.sizes.l}
          color="var(--dark-indigo)"
          multiline={false}
          bold
          thin={false}
        >
          {title}
        </Text>
      </Box>
    </Box>
  );
};
