import React, { VFC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TopicForm } from '../forms';
import { TopicsView } from '../topics-view/TopicsView';
import { TopicView } from '../topic-view/TopicView';

export const TopicsRouter: VFC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`} component={TopicForm} />
      <Route path={`${path}/:id`} component={TopicView} />
      <Route path={`${path}`} exact component={TopicsView} />
    </Switch>
  );
};
