import { useState } from 'react';

export enum SortKey {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ISortingHookModel {
  sort: SortKey;
  toggleSort: () => void;
}

export const useSorting = (defaultSortKey: SortKey): ISortingHookModel => {
  const [sort, setSort] = useState<SortKey>(defaultSortKey || SortKey.DESC);

  const toggleSort = () => {
    setSort((currentSort) => {
      if (currentSort === SortKey.ASC) {
        return SortKey.DESC;
      } else {
        return SortKey.ASC;
      }
    });
  };

  return { sort, toggleSort };
};
