import { createSelector } from '@reduxjs/toolkit';
import { chatsAdapter } from './chats.slice';
import { RootState } from 'config/store';

const generalSelector = (state: RootState) => state.chats;

export const chatsSelectors = {
  ...chatsAdapter.getSelectors(generalSelector),
  loading: createSelector(generalSelector, (chats) => chats.loading),
  pagination: createSelector(generalSelector, (chats) => chats.pagination),
  activeChat: createSelector(generalSelector, (chats) => chats.activeChat),
  notifications: createSelector(generalSelector, (chats) => chats.notifications),
};
