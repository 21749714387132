import { PollListTags } from './constants';
import { URGENT_THRESHOLD } from './poll-logic';

export const getPollFilterByTag = (tagFilter: string): Record<string, unknown> => {
  const nowTimestamp = Math.round(Date.now() / 1000);
  switch (tagFilter) {
    case PollListTags.ALL:
      return {};
    case PollListTags.ACTIVE:
      return { filterActiveFrom: nowTimestamp };
    case PollListTags.CLOSED:
      return { filterToDate: nowTimestamp };
    case PollListTags.URGENT:
      return { filterActiveFrom: nowTimestamp, filterActiveTo: nowTimestamp + URGENT_THRESHOLD };
    case PollListTags.COMPLETED:
      return { filterActiveTo: nowTimestamp };
    default:
      return {};
  }
};
