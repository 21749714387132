import React, { useEffect, VoidFunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, LoadingWrapper } from 'shared/components';
import { RootState } from 'config/store';
import { chatsSelectors } from '../../redux/chats.selectors';

import { fetchChat } from '../../redux/chats.slice';
import { ChatFooter } from './components/ChatFooter';
import { ChatMain } from './components/ChatMain';
import { ChatHeader } from './components/ChatHeader';
import { IActiveChat } from '../../redux/models';
import './Chat.scss';

type RouterParams = {
  id: string;
};

export const Chat: VoidFunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams<RouterParams>();
  const currentActiveChat = useSelector<RootState, IActiveChat | null>(chatsSelectors.activeChat);
  const loading = useSelector(chatsSelectors.loading)?.fetchOne;

  useEffect(() => {
    dispatch(fetchChat(id));
  }, [dispatch]);

  if (currentActiveChat) {
    return (
      <LoadingWrapper isLoading={loading}>
        <Box className="chat__container" flexY>
          <ChatHeader title={currentActiveChat?.header} />
          {/*@ts-ignore*/}
          <ChatMain>{currentActiveChat.messages || []}</ChatMain>
          <ChatFooter chatId={id} />
        </Box>
      </LoadingWrapper>
    );
  }

  return null;
};
