import { ListTags } from '../../constants/list-tags';

export const getFilterByTag = (tagFilter: string): Record<string, unknown> => {
  const nowTimestamp = Math.round(Date.now() / 1000);
  switch (tagFilter) {
    case ListTags.ALL:
      return {};
    case ListTags.ACTIVE:
      return { filterFromDate: nowTimestamp };
    case ListTags.CLOSED:
      return { filterToDate: nowTimestamp };
    case ListTags.URGENT:
      return { filterToDate: nowTimestamp };
    case ListTags.NEW:
      return {};
    default:
      return {};
  }
};
