const NAVIGATION_STRINGS_RUS = {
  main: 'Главная',
  topics: 'Обсуждения',
  polls: 'Голосования',
  events: 'Встречи',
  news: 'Новости',
  documents: 'Документы',
};

export default NAVIGATION_STRINGS_RUS;
