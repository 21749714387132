import classNames from 'classnames';
import React from 'react';
import { Card } from 'shared/components';

export const ListItemCard = ({ className, onClick, children }) => {
  return (
    <Card className={classNames('cmp-list-item-card', className)} onClick={onClick}>
      {children}
    </Card>
  );
};
