import React, { useEffect, useState, VFC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, ModuleContent } from 'shared/components';
import { usePagination } from 'shared/hooks/pagination';
import { useEntityFilters } from 'shared/hooks/filters';
import { getFilterByTag } from 'shared/service/common-entity-logic/filters';
import { SortKey, useSorting } from 'shared/hooks/sorting';
import { TopicFilterTags } from '../filters/tags/TopicFilterTags';
import { TopicsViewHeader } from '../topics-view-header/TopicsViewHeader';
import { TopicFilters } from '../filters/TopicFilters';
import { ITopicFilters } from '../../types';
import { useFetchTopicsQueryState } from '../../redux';
import { Tags } from '../../utils/constants';
import { TopicsViewPages } from './TopicsPages';
import './TopicsView.css';

export const TopicsView: VFC = () => {
  const [showFilters, setshowFilters] = useState(false);
  const [tag, setTag] = useState<string | null>(Tags.all);
  const { limit, page, setNextPage, reset } = usePagination();
  const { filters, resetFilters } = useEntityFilters<ITopicFilters>();
  const { sort } = useSorting(SortKey.DESC);

  const topicsLastPageData = useFetchTopicsQueryState({
    pagination: { limit, page },
    filters,
    sort,
  });

  const total = topicsLastPageData.data?.metadata.total || 0;

  const onFilterChange = (newFilters: ITopicFilters) => {
    resetFilters(newFilters);
    setTag(null);
  };

  const handleFiltersReset = () => {
    onFilterChange({});
    reset();
    setshowFilters(false);
    setTag(Tags.all);
  };

  const handleTagChange = (tagName: string) => {
    setTag(tagName);
    reset();
    resetFilters(getFilterByTag(tagName));
  };

  useEffect(() => {
    reset();
  }, [filters]);

  return (
    <ModuleContent>
      <Box className="topics-header-block" flexY>
        <TopicsViewHeader
          topicsCount={total}
          filterActive={filters && !isEmpty(filters)}
          onFilterClick={() => setshowFilters(!showFilters)}
        />
        {showFilters && (
          <Box>
            <TopicFilters
              filters={filters}
              onChange={onFilterChange}
              onReset={handleFiltersReset}
            />
          </Box>
        )}
        <TopicFilterTags tag={tag} onChange={handleTagChange} />
      </Box>

      <TopicsViewPages
        pagination={{ page, limit, total, setNextPage }}
        filters={filters}
        sort={sort}
      />
    </ModuleContent>
  );
};
