import { PollListTags } from '../utils/constants';

const POLLS_STRINGS_RUS = {
  polls: 'Голосования',
  create_poll: 'Создать голосование',
  filter: {
    header: '',
  },
  loading: 'Загрузка голосований...',
  polls_count: 'Открыто {{pollsCount}} голосований',
  poll: {
    createdAt: 'Создано: ',
    activeUntil: 'Активно до: ',
    signingTitle: 'Подпишите свой голос',
    signingAlert: 'Пожалуйста, удостоверьтесь, что все выбранные Вами варианты ответа верны.',
    sign: 'Подписать',
    yourSelection: 'Вы выбрали',
    yourVoted: 'Вы проголосовали за этот вариант',
    back: 'Назад',
    ended: 'Завершено',
    success: 'Голосование состоялось',
    failed: 'Голосование не состоялось',
    askToParticipate: 'Хотели бы вы участвовать?',
    chairman: 'Председатель',
    vote: 'Проголосовать',
    participation: {
      yes: 'Я буду участвовать',
      no: 'Я не буду участвовать',
      skip: 'Воздержусь',
    },
    isParticipated: 'Вы сделали свой выбор',
    notParticipated: 'Не хватает вашего голоса',
    discuss: 'Обсудить это голосование',
    status: {
      label: 'статус',
      urgent: 'Срочное',
      active: 'Активное',
    },
    voting: 'Голосование',
    cancel: 'Отменить',
  },
  form: {
    header: 'Создать голосование',
    publish: 'Опубликовать голосование',
    title: 'Заголовок',
    description: 'Описание',
    setActiveUntil: 'Установить дедлайн',
    questions: 'Вопросы',
    addQuestion: 'Добавить вопрос',
    deleteQuestion: 'Удалить вопрос',
    answers: 'Варианты ответов',
    addAnswer: 'Добавить вариант ответа',
  },
  tags: {
    [PollListTags.ACTIVE]: 'Активные',
    [PollListTags.ALL]: 'Все',
    [PollListTags.URGENT]: 'Срочные',
    [PollListTags.CLOSED]: 'Закрытые',
    [PollListTags.COMPLETED]: 'Новые',
  },
  quorum: 'Кворум',
};

export default POLLS_STRINGS_RUS;
