import React, { Ref } from 'react';
import 'moment/locale/ru';
import 'moment/locale/et';
import { InputProps } from '@material-ui/core';
import { Modifier } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { InputClassNames } from 'react-day-picker/types/ClassNames';
import { dateUtils } from 'shared/service';
import { Input } from '../Input/Input';

function mergeClassNames(
  classNames: Partial<InputClassNames> | undefined,
  defaultClassNames: InputClassNames,
): InputClassNames {
  if (!classNames) {
    return defaultClassNames;
  }
  const mergeClass = (key: keyof InputClassNames) => {
    return `${defaultClassNames[key]}${classNames[key] ? ' ' + classNames[key] : ''}`;
  };
  return {
    container: mergeClass('container'),
    overlay: mergeClass('overlay'),
    overlayWrapper: mergeClass('overlayWrapper'),
  };
}

const DayPickerCustomInput = React.forwardRef((props, ref) => {
  return <Input ref={ref} {...props} />;
});
DayPickerCustomInput.displayName = 'DayPickerCustomInput';

interface IDatePickerProps {
  value: number | undefined;
  classNames?: Partial<InputClassNames>;
  selected?: Modifier | Modifier[];
  disabled?: Modifier | Modifier[];
  months?: number;
  inputRef?: Ref<HTMLInputElement>;
  onDayClick?: () => void;
  onChange: (value: number) => void;
}

export const DatePicker = React.forwardRef<DayPickerInput, IDatePickerProps>(
  ({ value, classNames, months = 1, selected, disabled, inputRef, onChange, onDayClick }) => {
    const defaultClassNames = {
      container: 'DayPickerInput',
      overlay: 'DayPickerInput-Overlay',
      overlayWrapper: 'DayPickerInput-OverlayWrapper',
    };

    const handleDayChange = (date: Date) => {
      const dayStart = dateUtils.getStartOfDay(date);
      onChange(dayStart);
    };

    const currentLocale: string | null = localStorage.getItem('i18nextLng') || 'en';

    return (
      <DayPickerInput
        classNames={mergeClassNames(classNames, defaultClassNames)}
        keepFocus={true}
        value={value ? dateUtils.getDate(value) : undefined}
        onDayChange={handleDayChange}
        component={(props: InputProps) => <DayPickerCustomInput {...props} ref={inputRef} />}
        dayPickerProps={{
          numberOfMonths: months,
          onDayClick,
          selectedDays: selected,
          disabledDays: disabled,
          localeUtils: MomentLocaleUtils,
          locale: currentLocale,
        }}
      />
    );
  },
);
DatePicker.displayName = 'DatePicker';
