import React, { FC } from 'react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { useHistory } from 'react-router-dom';
import { Text } from '../../Text/Text';
import { Box } from '../../layout/Box';
import './BackRouting.css';

interface IProps {
  text?: string;
}

export const BackRouting: FC<IProps> = ({ text }) => {
  const history = useHistory();
  return (
    <Box flexX className="cmp-back-routing" onClick={() => history.goBack()}>
      <ArrowBack />
      {/* @ts-ignore */}
      <Box>{Boolean(text) && <Text>{text}</Text>}</Box>
    </Box>
  );
};
