import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { apiService } from 'shared/service';
import {
  FetchEntityResponse,
  FetchListResponse,
  IFetchListArgs,
} from 'shared/service/common-entity-logic/types';
import { SubmitPollModel } from '../types/types';
import { IPoll } from '../types/models';
import { POLLS_MODULE_KEY } from '../utils';

const cache = {
  type: 'Polls',
  listId: 'LIST_POLLS',
};

export const pollsApi = createApi({
  reducerPath: 'polls',
  baseQuery: apiService.rtkFetch,
  tagTypes: [cache.type],
  endpoints: (builder) => ({
    fetchPolls: builder.query<FetchListResponse<IPoll>, IFetchListArgs>({
      query: (args) => `${POLLS_MODULE_KEY}?${apiService.rtkGetUrlParams(args)}`,
      transformResponse: (value: FetchListResponse<IPoll>) => {
        return value;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ pollId }) => ({ type: cache.type, id: pollId })),
              { type: cache.type, id: cache.listId },
            ]
          : [{ type: cache.type, id: cache.listId }],
    }),
    fetchPoll: builder.query<FetchEntityResponse<IPoll>, string>({
      query: (id) => `${POLLS_MODULE_KEY}/${id}`,
      providesTags: (result) => [{ type: cache.type, id: result?.data.pollId }],
    }),
    createPoll: builder.mutation<IPoll, unknown>({
      query: (model) => ({ url: `${POLLS_MODULE_KEY}`, method: 'POST', body: model }),
      invalidatesTags: [{ type: cache.type, id: cache.listId }],
    }),
    submitPoll: builder.mutation<unknown, SubmitPollModel>({
      query: (model) => ({
        url: `${POLLS_MODULE_KEY}/${model.id}/submit`,
        method: 'POST',
        body: model.data,
      }),
      invalidatesTags: (result, error, model) => [{ type: cache.type, id: model.id }],
    }),
  }),
});

export const {
  useFetchPollsQuery,
  endpoints: {
    fetchPolls: { useQueryState: useFetchPollsQueryState },
  },
  useFetchPollQuery,
  useCreatePollMutation,
  useSubmitPollMutation,
} = pollsApi;
