import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { apiService } from 'shared/service';
import { AppDispatch } from 'config/store';
import { IChat, IChatResponse } from '../models/chat.model';
import { MESSAGE_STATUS } from './const';
import { INotification, ISliceState, Sort } from './models';

type EntityChatState = EntityState<IChat> & ISliceState;

interface IFetchChatsParams {
  filters: IFilters;
  pagination?: IPagination;
}

interface IFilters {
  filterHeaderText?: string;
  sort: Sort;
}

interface IPagination {
  page: number;
  limit: number;
}

export const fetchChats = createAsyncThunk('chats/fetchAll', async (params: IFetchChatsParams) => {
  return apiService.read('chats', params);
});

export const fetchChat = createAsyncThunk('chats/fetchOne', async (id: string) => {
  return apiService.read(`chats/${id}`);
});

export const refetchChats = (params?: IFetchChatsParams) => async (dispatch: AppDispatch | any) => {
  await dispatch(reset());
  return dispatch(
    fetchChats({
      ...params,
      filters: { ...params?.filters, sort: 'desc' },
      pagination: apiService.getDefaultPagination(),
    }),
  );
};

const isLoadedAll = (state: EntityChatState, payload: IChatResponse) =>
  state.ids.length + payload.data.length === payload.metadata.total;

export const chatsAdapter = createEntityAdapter<IChat>({
  selectId: (chat) => chat.id,
});

const messagesSlice = createSlice({
  name: 'chats',
  initialState: chatsAdapter.getInitialState<ISliceState>({
    pagination: { ...apiService.getDefaultPagination(), loadedAll: false, total: 0 },
    loading: {
      fetchAll: false,
      fetchOne: false,
    },
    activeChat: null,
    notifications: [],
  }),
  reducers: {
    reset(state: EntityChatState) {
      state.pagination = { ...apiService.getDefaultPagination(), loadedAll: false, total: 0 };
      chatsAdapter.removeAll(state);
    },
    messageReceived(state: EntityChatState, { payload }) {
      if (state.activeChat && state.activeChat?.id === payload.chatId) {
        state.activeChat.messages = [...state.activeChat.messages, payload];
        state.notifications = [
          ...state.notifications,
          {
            count: 1,
            id: payload.id,
            type: 'CHAT',
          },
        ];
      }
    },
    messageSent(state: EntityChatState, { payload }) {
      if (state.activeChat && state.activeChat?.id === payload.chatId) {
        state.activeChat.messages = [...state.activeChat.messages, payload];
      }
    },
    messageSentAck(state: EntityChatState, { payload }) {
      if (state.activeChat && state.activeChat?.id === payload.chatId) {
        state.activeChat.messages = state.activeChat.messages.map((msg) => {
          return payload.msgSentAt === msg.msgSentAt
            ? {
                ...msg,
                ...payload,
                status: MESSAGE_STATUS.delivered,
                isMine: true,
              }
            : msg;
        });
      }
    },
    setNotifications(state: EntityChatState, { payload }) {
      state.notifications = payload;
    },
    addNewNotifications(state: EntityChatState, { payload }) {
      state.notifications.push(...payload);
    },
    removeNotifications(state: EntityChatState, { payload }) {
      state.notifications = state.notifications.filter(
        ({ id }) => !payload.some((notification: INotification) => notification.id === id),
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state: EntityChatState) => {
        state.loading.fetchAll = true;
      })
      .addCase(fetchChats.fulfilled, (state: EntityChatState, action) => {
        isLoadedAll(state, action.payload)
          ? (state.pagination.loadedAll = true)
          : (state.pagination.page += 1);
        state.pagination.total = action.payload.metadata.total;
        state.loading.fetchAll = false;
        chatsAdapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchChat.pending, (state: EntityChatState) => {
        state.loading.fetchOne = true;
      })
      .addCase(fetchChat.fulfilled, (state: EntityChatState, action) => {
        state.loading.fetchOne = false;
        state.activeChat = {
          header: action?.payload?.data[0]?.header,
          messages: action?.payload?.data,
          id: action?.meta.arg,
        };
      });
  },
});

export const {
  reducer,
  actions: { reset, setNotifications, messageReceived, messageSent, messageSentAck },
} = messagesSlice;

export default reducer;
