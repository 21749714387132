const EVENTS_STRINGS_EST = {
  events: 'Events',
  type: {
    all: 'All',
    official: 'Official',
    unofficial: 'Unofficial',
    ended: 'Ended',
  },
  form: {
    header: 'Create an event',
    title: 'Title',
    description: 'Description',
    date: 'Set due date',
    time: 'Event time',
    topic: 'Link to a topic',
    publish: 'Publish the event',
  },
  event: {
    author: 'Author',
    back: 'Back to event list',
    is_ended: 'Ended',
    date: 'Date',
    discuss: 'Discuss the event',
    createDiscussion: 'Start discussion',
  },
  create_event: 'Create an event',
  loading: 'Loading...',
};

export default EVENTS_STRINGS_EST;
