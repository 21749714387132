import React, { useState, useContext, FC, ChangeEvent, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { Box, SocketContext } from 'shared/components';
import { WS_EVENTS_OUT } from 'shared/service/socket';
import { messageSent } from 'modules/accounts/components/Messages/redux/chats.slice';
import { MESSAGE_STATUS } from '../../../../redux/const';
import { ISocketContext } from 'shared/components/SocketProvider/SocketContext';
import './ChatFooter.scss';

interface IProps {
  chatId: string;
}

export const ChatFooter: FC<IProps> = ({ chatId }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const { sendMessage } = useContext<ISocketContext>(SocketContext);
  const dispatch = useDispatch();

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (message) {
      const msgSentAt = Date.now();
      const payload = {
        chatId,
        msg: message,
        msgSentAt,
      };
      if (typeof sendMessage === 'function') {
        sendMessage(WS_EVENTS_OUT.msgSent, payload);
      }
      dispatch(
        messageSent({
          chatId,
          message,
          sender: { displayName: '' },
          msgSentAt,
          timestamp: msgSentAt,
          status: MESSAGE_STATUS.sent,
          isMine: true,
        }),
      );
      setMessage('');
    }
  };

  return (
    <Box flexX className="chat-footer">
      <form className="chat-footer__form" onSubmit={onSubmit} noValidate autoComplete="off">
        <Box flexX className="chat-footer__input-container">
          <TextField
            size="small"
            fullWidth
            className="chat-footer__input"
            value={message}
            name="message"
            onChange={onChangeInput}
            placeholder={t('MESSAGES_STRINGS.typeMessagePlaceholder')}
            variant="outlined"
            color="primary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" aria-label="send button" edge="end">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </form>
    </Box>
  );
};
