import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WS_EVENTS_IN } from 'shared/service/socket';
import {
  messageReceived,
  messageSentAck,
} from 'modules/accounts/components/Messages/redux/chats.slice';

export const useSocket = (socket) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onConnected = (data) => {
    // @todo: implement connected callback
  };

  const onConnectError = () => {
    // @todo: implement error callback
  };

  const onDisconnected = () => {
    // @todo: implement disconnected callback
  };

  const onException = (exception) => {
    console.error('onException', exception);
  };

  const onChatStartedAck = (chat) => {
    history.push(`/account/chats/${chat.chatId}`);
  };

  const onMsgSentAck = (data) => {
    dispatch(messageSentAck(data));
  };

  const onMsgReceived = (data) => {
    dispatch(messageReceived(data));
    // @todo: remove console.log
    console.log('onMsgReceived', data);
  };

  const sendMessage = (event, payload) => {
    socket.emit(event, payload);
  };

  useEffect(() => {
    socket.connect();
    socket.on('connect', onConnected);
    socket.on('disconnect', onDisconnected);
    socket.on('connect_error', onConnectError);
    socket.on('exception', onException);

    socket.on(WS_EVENTS_IN.chatStartedAck, onChatStartedAck);
    socket.on(WS_EVENTS_IN.msgSentAck, onMsgSentAck);
    socket.on(WS_EVENTS_IN.msgReceived, onMsgReceived);

    return () => socket.close();
  }, []);

  return { sendMessage };
};
