import React from 'react';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { default as MuiCheckbox } from '@material-ui/core/Checkbox';
import { Box } from 'shared/components';
import { Label } from 'shared/components/form-controls/Label/Label';
import { GenericControl } from '../GenericControl/GenericControl';

export const Checkbox = ({ label, className, control, name }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <GenericControl className={classNames(className, 'checkbox-control')}>
      {label && <Label>{label}</Label>}
      <Box>
        <MuiCheckbox checked={value} value={value} onChange={handleChange} />
      </Box>
    </GenericControl>
  );
};

Checkbox.displayName = 'Checkbox';
