import * as Yup from 'yup';
import i18n from 'i18next';
// TODO fix translation
export const validationSchema = Yup.object({
  header: Yup.string()
    .max(50, i18n.t('MESSAGES_STRINGS.errors.lengthLess50'))
    .required(i18n.t`MESSAGES_STRINGS.errors.requiredField'`),
  msg: Yup.string().required(i18n.t('MESSAGES_STRINGS.errors.requiredField')),
  person: Yup.object()
    .shape({
      correspondentRef: Yup.string(),
      correspondentType: Yup.string(),
      correspondentDisplayName: Yup.string(),
    })
    .required(i18n.t('MESSAGES_STRINGS.errors.requiredField'))
    .nullable(true),
});

export const validate = async (fields: unknown) => {
  return validationSchema.validate(fields, { abortEarly: false });
};
