import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackRouting, ModuleContent } from 'shared/components';

import './AccountSubPage.css';

export const AccountSubPage = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ModuleContent className="account-subpage__container" flexY>
      <BackRouting text={t('SHARED_STRINGS.backToAccount')} />
      {children}
    </ModuleContent>
  );
};
