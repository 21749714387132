import React, { FC } from 'react';
import { Box, InputDateRange } from 'shared/components';
import './FiltersDates.scss';

export interface IDateFilterParams {
  from?: number;
  to?: number;
}

interface IProps extends IDateFilterParams {
  onChange: (args: IDateFilterParams) => void;
}

export const FiltersDates: FC<IProps> = ({ from, to, onChange }) => {
  return (
    <Box flexX className="filters-dates">
      <InputDateRange from={from} to={to} onChange={onChange} />
    </Box>
  );
};
