const NAVIGATION_STRINGS_ENG = {
  main: 'Main',
  topics: 'Topics',
  polls: 'Polls',
  events: 'Events',
  news: 'News',
  documents: 'Documents',
};

export default NAVIGATION_STRINGS_ENG;
