import { AnyAction, configureStore, Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { topicsApi } from 'modules/topics';
import { pollsApi } from 'modules/polls';
import { eventsReducer } from 'modules/events';
import { errorsReducer } from 'shared/redux';
import { accountApi, chatsReducer } from 'modules/accounts';

const preloadedState = {};

export const store = configureStore({
  reducer: {
    [pollsApi.reducerPath]: pollsApi.reducer,
    events: eventsReducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    chats: chatsReducer,
    globalErrors: errorsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(accountApi.middleware, topicsApi.middleware, pollsApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch<AnyAction> => useDispatch<AppDispatch>();
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
