import React, { FC } from 'react';
import classNames from 'classnames';
import { Text } from '../Text/Text';
import './Tag.css';

interface IProps {
  className?: string;
  selected?: boolean;
  onClick?: () => unknown;
}

export const Tag: FC<IProps> = ({ className, selected = false, onClick, children }) => {
  const isContainer = typeof children !== 'string';
  return (
    <span
      className={classNames('cmp-tag', className, {
        'cmp-tag--selected': selected,
        'cmp-tag--container': isContainer,
      })}
      onClick={onClick}
    >
      {!isContainer ? <Text>{children}</Text> : children}
    </span>
  );
};
