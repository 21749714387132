import React, { useState, VFC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BackRouting, Box, Text, ModuleContent, LoadingWrapper } from 'shared/components';
import { isPollFinish } from '../../utils';
import { AnswerFormValues, AnswersForm } from './AnswersForm/AnswersForm';
import { Participate } from './Participate/Participate';
import { ResultsList } from '../poll-results/results-list/ResultsList';
import { PollMainInfo } from '../main-info/PollMainInfo';
import { useFetchPollQuery, useSubmitPollMutation } from '../../redux/polls.slice';
import { ParticipationStatus } from '../../types/types';
import './PollView.css';

export const PollView: VFC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: pollResponse, isFetching: loading } = useFetchPollQuery(id);
  const [participation, setParticipation] = useState(null);

  const [submitPoll, { isLoading: isSubmitPollLoading }] = useSubmitPollMutation();

  const handleSubmitPoll = async (data: AnswerFormValues) => {
    const submitModelData = Object.keys(data).map((questionId) => ({
      questionId,
      answerId: data[questionId],
    }));

    const result = await submitPoll({ id, data: submitModelData });
    if ('data' in result) {
      history.push('/polls');
    }
  };

  if (!pollResponse || !pollResponse.data) {
    return null;
  }

  const { data: poll } = pollResponse;

  return (
    <LoadingWrapper isLoading={loading}>
      <ModuleContent>
        <Box flexY gapDouble>
          <Box>
            <BackRouting text={t('POLLS_STRINGS.poll.back')} />
          </Box>
          <Box className="poll-view__header">
            <p>
              <Text size={Text.sizes.xxxl} bold>
                {poll.title}
              </Text>
            </p>
            <p>
              <Text size={Text.sizes.l} thin multiline>
                {poll.description}
              </Text>
            </p>
          </Box>
          <PollMainInfo poll={poll} />

          {!isPollFinish(poll.activeUntil) && poll.isParticipated ? (
            <ResultsList questions={poll.questions} />
          ) : (
            <>
              <Box className="poll-view__participate">
                <Participate onChange={setParticipation} />
              </Box>
              {participation === ParticipationStatus.yes && (
                <Box className="poll-view__question-answering">
                  <AnswersForm
                    questions={poll.questions}
                    loading={isSubmitPollLoading}
                    onSubmit={handleSubmitPoll}
                  />
                </Box>
              )}
            </>
          )}

          {/* <ActionButton fullWidth>{POLLS_STRINGS.poll.discuss}</ActionButton> */}
        </Box>
      </ModuleContent>
    </LoadingWrapper>
  );
};
