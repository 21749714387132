import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { Box, Input } from 'shared/components';
import './Sender.css';

interface IProps {
  loading: boolean;
  onSend: (arg: string) => void;
}

export const Sender: FC<IProps> = ({ loading, onSend }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');

  const handleSend = () => {
    onSend(value);
    setValue('');
  };

  return (
    <Box className="comments-sender" flexX>
      <Input
        className="comments-sender__input"
        placeholder={t('TOPICS_STRINGS.topic.inputPlaceholder')}
        value={value}
        disabled={loading}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
        onKeyEnter={handleSend}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                aria-label="send button"
                edge="end"
                disabled={loading}
                onClick={handleSend}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {loading && t('TOPICS_STRINGS.topic.loading')}
    </Box>
  );
};
