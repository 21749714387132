import { config } from 'config/config';

export function getUrl(accountName: string): string | undefined {
  const { feedbackFormUrl } = config;
  if (!feedbackFormUrl) {
    console.error('feedback form url is not set in config');
    return;
  }
  const url = new URL(feedbackFormUrl);
  const accountNameParam = Array.from(url.searchParams.keys()).find((key) =>
    key.startsWith('entry'),
  );
  if (!accountNameParam) {
    console.error('account name param in feedback form not found');
  } else {
    url.searchParams.set(accountNameParam, accountName);
  }

  return url.toString();
}
