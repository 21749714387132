import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackRouting,
  Box,
  ActionButton,
  Tag,
  Text,
  ModuleContent,
  Card,
  CardMainTitle,
  CardSection,
  RenderTextField,
} from 'shared/components';
import { dateUtils } from 'shared';
import { ROUTES } from 'shared/constants';
import { Author } from 'modules/topics/components';
import { eventUtils } from '../../utils';
import { eventsSelectors, fetchEvent } from '../../redux';

export const EventView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const event = useSelector(eventsSelectors.event);
  const loading = useSelector(eventsSelectors.loading)?.fetchOne;

  const handleDiscuss = () => {
    history.push(`${ROUTES.topics}/${event.topicId}`);
  };

  const handleCreateDiscuss = () => {
    history.push(`${ROUTES.topics}/new`);
  };

  useEffect(() => {
    dispatch(fetchEvent(id));
  }, []);
  if (loading) {
    return t('EVENTS_STRINGS.loading');
  }
  if (!event) {
    return null;
  }

  return (
    <ModuleContent className="event-view">
      <Box>
        <BackRouting text={t('EVENTS_STRINGS.event.back')} />
      </Box>
      {eventUtils.isEnded(event) && <Tag>{t('EVENTS_STRINGS.event.is_ended')}</Tag>}
      <Box>
        <p>
          <Text size={Text.sizes.xxxl} bold>
            {event.title}
          </Text>
        </p>
        <p>
          <Text size={Text.sizes.l} thin multiline>
            {event.description}
          </Text>
        </p>
      </Box>
      <Card>
        <CardMainTitle />
        <CardSection>
          <RenderTextField label={t('EVENTS_STRINGS.event.date')}>
            {dateUtils.getDisplayTime(event.startsAt)}
          </RenderTextField>
        </CardSection>

        <Box>
          <Author user={event.author} createdAt={event.createdAt} />
        </Box>
      </Card>
      <Box>
        {event.topicId ? (
          <ActionButton fullWidth onClick={handleDiscuss}>
            {t('EVENTS_STRINGS.event.discuss')}
          </ActionButton>
        ) : (
          <ActionButton fullWidth onClick={handleCreateDiscuss}>
            {t('EVENTS_STRINGS.event.createDiscussion')}
          </ActionButton>
        )}
      </Box>
    </ModuleContent>
  );
};
