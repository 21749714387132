import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import ENG from 'locales/eng';
import RUS from 'locales/rus';
import EST from 'locales/est';

const resources = {
  et: {
    translation: EST,
  },
  ru: {
    translation: RUS,
  },
  en: {
    translation: ENG,
  },
};

const detectorOptions = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: detectorOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        const date = value instanceof Date ? value : new Date(value * 1000);
        return moment(date).locale(lng).format(format);
      },
    },
  });

export default i18n;
