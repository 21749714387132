import React, { FC } from 'react';
import { EntityFilters } from 'shared/hooks/filters';
import { SortKey } from 'shared/hooks/sorting';
import { useFetchPollsQuery } from '../../redux/polls.slice';
import { PollsList } from '../list/PollsList';

interface IProps {
  page: number;
  limit: number;
  filters: EntityFilters;
  sort: SortKey;
}

export const PollsViewPage: FC<IProps> = ({ page, limit, filters, sort }) => {
  const { data: pollsData, isFetching } = useFetchPollsQuery({
    pagination: { limit, page },
    filters,
    sort,
  });

  if (!pollsData?.data) return null;

  return <PollsList polls={pollsData.data} loading={isFetching} />;
};
