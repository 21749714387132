import { useState } from 'react';
import debounce from 'lodash/debounce';
import { apiService } from 'shared/service';
import { IChatParticipant } from '../../models/chat.model';

interface IReturn {
  participants: IChatParticipant[];
  isLoading: boolean;
  fetchParticipants: any;
}

export const useParticipants = (): IReturn => {
  const [participants, setParticipants] = useState<IChatParticipant[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchParticipants = async (input: string): Promise<void> => {
    if (!input) {
      setParticipants([]);
      return;
    }
    setLoading(true);
    const params = {
      pagination: {
        page: 0,
        limit: 10,
      },
      filters: {
        filter: input,
      },
    };
    try {
      const { data } = await apiService.read('chats/participants', params);
      setParticipants(data);
    } catch (error) {
      console.error(error);
      setParticipants([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    participants,
    isLoading,
    fetchParticipants: debounce<(input: string) => Promise<void>>(fetchParticipants, 400),
  };
};
