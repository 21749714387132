const NAVIGATION_STRINGS_EST = {
  main: 'Avaleht',
  topics: 'Teemad',
  polls: 'Hääletamine',
  events: 'Sündmused',
  news: 'Uudised',
  documents: 'Dokumentatsioon',
};

export default NAVIGATION_STRINGS_EST;
