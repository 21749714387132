import React, { ReactElement } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LoginPage, AppRootPage } from '../pages';
import { store } from './store';
import { ROUTES } from 'shared/constants';

export const App = (): ReactElement => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path={ROUTES.auth.auth} component={LoginPage} />
          <Route path="" component={AppRootPage} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};
