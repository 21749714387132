import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UrgentIcon } from 'assets/icons/urgent-poll.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/completed.svg';
import {
  Box,
  BoxItemDivider,
  Card,
  CardMainTitle,
  CardSection,
  Tag,
  Text,
} from 'shared/components';
import { RenderFieldLabel, RenderTextField } from 'shared/components/field-renderers';
import { renderDate } from 'shared/service/locales';
import { Author } from 'modules/topics/components';
import { isPollFinish, isPollUrgent, isQuorumReached } from '../../utils';
import { IPoll } from '../../../polls/types/models';

interface IProps {
  poll: IPoll;
}

export const PollMainInfo: FC<IProps> = ({ poll }) => {
  const { t } = useTranslation();

  return (
    <Card className="poll-view__main">
      <CardMainTitle />
      <h1>{t('Welcome to React')}</h1>
      <CardSection flexX spaceBetween>
        <RenderFieldLabel>{t('POLLS_STRINGS.poll.status.label')}</RenderFieldLabel>
        {isPollFinish(poll.activeUntil) &&
          (isQuorumReached(poll.questions) ? (
            <Tag>
              <SuccessIcon />
              <Text>{t('POLLS_STRINGS.poll.success')}</Text>
            </Tag>
          ) : (
            <Tag>{t('POLLS_STRINGS.poll.failed')}</Tag>
          ))}
        {!isPollFinish(poll.activeUntil) &&
          (isPollUrgent(poll.activeUntil) ? (
            <Tag>
              <UrgentIcon />
              <Text>{t('POLLS_STRINGS.poll.status.urgent')}</Text>
            </Tag>
          ) : (
            <Tag>
              <ActiveIcon />
              <Text>{t('POLLS_STRINGS.poll.status.active')}</Text>
            </Tag>
          ))}
      </CardSection>
      <CardSection flexX gapDouble>
        <RenderTextField label={t('POLLS_STRINGS.poll.createdAt')}>
          {renderDate(poll.createdAt)}
        </RenderTextField>
        <BoxItemDivider />
        <RenderTextField label={t('POLLS_STRINGS.poll.activeUntil')}>
          {renderDate(poll.activeUntil)}
        </RenderTextField>
      </CardSection>
      <Box className="poll-view__chairman" flexY gap>
        <RenderFieldLabel>{t('POLLS_STRINGS.poll.chairman')}</RenderFieldLabel>
        <Author user={poll.author} />
      </Box>
    </Card>
  );
};
