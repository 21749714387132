import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { config } from 'config/config';
import {
  Box,
  FormHeader,
  Input,
  InputTime,
  Text,
  Textarea,
  InputDateControl,
  Checkbox,
  CardSection,
  ModuleContent,
  ActionButton,
} from 'shared/components';
import { ROUTES } from 'shared/constants';
import { addEvent, eventsSelectors } from '../../redux';
import { TopicSelector } from './TopicSelector/TopicSelector';
import { dateUtils } from 'shared';
import './EventForm.css';

export const EventForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(eventsSelectors.loading)?.create;

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: dateUtils.getStartOfDay(new Date()),
      timeFrom: 0,
      timeTo: 0,
      isOfficial: false,
    },
  });

  const onSubmit = async ({ title, description, date, timeFrom, timeTo, topicId, isOfficial }) => {
    const payload = {
      title,
      description,
      startsAt: date + timeFrom,
      endsAt: date + timeTo,
      geoPoint: [config.userDefaults.eventPoint.latitude, config.userDefaults.eventPoint.longitude],
      topicId,
      isOfficial,
    };
    const action = await dispatch(addEvent(payload));
    if (!action.error) {
      history.push(ROUTES.events);
    }
  };

  return (
    <ModuleContent light>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader text={t('EVENTS_STRINGS.form.header')} closeTo={ROUTES.events} />

        <CardSection>
          <Text size={Text.sizes.xl}>{t('SHARED_STRINGS.card.main_info')}</Text>
        </CardSection>

        <Input
          {...register('title', { required: true, maxLength: 150 })}
          label={t('EVENTS_STRINGS.form.title')}
          error={errors?.title?.message || errors?.title?.type}
        />
        <Textarea
          rows={6}
          {...register('description', { required: true })}
          label={t('EVENTS_STRINGS.form.description')}
          error={errors?.description?.message || errors?.description?.type}
        />
        <InputDateControl
          label={t('EVENTS_STRINGS.form.date')}
          name="date"
          control={control}
          required
          error={errors?.date?.message || errors?.date?.type}
        />

        <Checkbox name="isOfficial" control={control} label={t('EVENTS_STRINGS.type.official')} />

        <Box flexX gapDouble className="event-form__times">
          <InputTime label={t('EVENTS_STRINGS.form.time')} name="timeFrom" control={control} />
          <InputTime name="timeTo" control={control} min={getValues().timeFrom} />
        </Box>

        <Box flexY gapDouble>
          <TopicSelector name="topicId" control={control} />

          <ActionButton
            fullWidth
            type="submit"
            disabled={!isDirty || !isValid || loading}
            loading={loading}
          >
            {t('EVENTS_STRINGS.form.publish')}
          </ActionButton>
        </Box>
      </form>
    </ModuleContent>
  );
};
