import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardSection, Input, Text } from 'shared/components';
import { FiltersButtons, FiltersDates } from 'shared/components/module-blocks';
import { eventsSelectors, filter, resetFilter, setFilters } from '../../redux';

export const EventFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(eventsSelectors.loading)?.fetchAll;
  const pagination = useSelector(eventsSelectors.pagination);
  const filters = useSelector(eventsSelectors.filters);

  const handleReset = () => {
    dispatch(resetFilter({ filters, pagination }));
  };

  const handleApply = () => {
    dispatch(filter({ filters, pagination }));
  };

  const handleFilterDateChange = ({ from, to }) => {
    if (from) {
      dispatch(setFilters({ filterFromDate: from }));
    }
    if (to) {
      dispatch(setFilters({ filterToDate: to }));
    }
  };

  return (
    <Card>
      <CardSection>
        <Text size={Text.sizes.xl}>{t('SHARED_STRINGS.filters.__title')}</Text>
      </CardSection>
      <Input
        onChange={(event) => {
          dispatch(setFilters({ filterHeaderText: event.target.value }));
        }}
        onKeyEnter={handleApply}
        value={filters.filterHeaderText || ''}
        label={t('SHARED_STRINGS.search')}
      />

      <FiltersDates
        from={filters.filterFromDate}
        to={filters.filterToDate}
        onChange={handleFilterDateChange}
      />

      <FiltersButtons loading={loading} onReset={handleReset} onApply={handleApply} />
    </Card>
  );
};
