import React from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Box, Button, ActionButton } from 'shared/components';
import './FilterButtons.css';

export const FiltersButtons = ({ loading, onReset, onApply }) => {
  const { t } = useTranslation();
  return (
    <Box flexX className="cmpmd-filter-buttons">
      <Button className="cmpmd-filter-buttons__clear" onClick={onReset} disabled={loading}>
        <Box flexX>
          <IoClose />
          {loading ? 'loading' : t('SHARED_STRINGS.filters.reset')}
        </Box>
      </Button>
      <ActionButton onClick={onApply} disabled={loading}>
        {loading ? 'loading' : t('SHARED_STRINGS.filters.apply')}
      </ActionButton>
    </Box>
  );
};
