import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

const getDisplayTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return `${date.toDateString()} ${date
    .toLocaleTimeString(undefined, { hour12: false })
    .substr(0, 5)
    .replace('24', '00')}`;
};

const getDate = (timestamp: number): Date | undefined => {
  if (isNaN(timestamp)) {
    return;
  }
  return new Date(timestamp * 1000);
};
const getTimestamp = (date: Date): number => date.getTime() / 1000;
const getStartOfDay = (date: Date): number => date.setHours(0, 0, 0, 0) / 1000;

const parseTimeToSeconds = (time: string): number => {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
};
function secondsToTimeString(seconds = 0): string {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export const dateUtils = {
  getDisplayTime,
  getDate,
  getTimestamp,
  getStartOfDay,
  parseTimeToSeconds,
  secondsToTimeString,
};

export const formatDate = (date: number | Date, dateFormat = 'dd/MM/yyyy'): string =>
  format(date, dateFormat);

export const formatDurationFromNow = (date: number | Date): string =>
  formatDistanceToNowStrict(date);
