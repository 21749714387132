export const config = {
  contactMail: process.env.REACT_APP_CONTACT_MAIL,
  feedbackFormUrl: process.env.REACT_APP_FEEDBACK_FORM_URL,
  api: {
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    socketUrl: process.env.REACT_APP_SOCKET_URL,
    defaultDevCredentials: {
      username: process.env.REACT_APP_DEFAULT_USERNAME,
      password: process.env.REACT_APP_DEFAULT_PASSWORD,
    },
  },
  userDefaults: {
    eventPoint: {
      latitude: 0,
      longitude: 0,
    },
  },
};
