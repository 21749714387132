import React from 'react';
import DayPicker from 'react-day-picker';
import { Controller } from 'react-hook-form';
import { dateUtils } from 'shared/service';
import 'react-day-picker/lib/style.css';

const DatePickerController = ({ field: { onChange, value }, onChange: onChangeCallback }) => {
  const onDayClick = (date) => {
    const newValue = dateUtils.getTimestamp(date);
    onChange(newValue);
    onChangeCallback && onChangeCallback(newValue);
  };
  const modifiers = { highlighted: dateUtils.getDate(value), selected: dateUtils.getDate(value) };
  return (
    <>
      <DayPicker modifiers={modifiers} onDayClick={onDayClick} />
    </>
  );
};

export const DatePicker = ({ control, name, onChange }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => <DatePickerController {...props} onChange={onChange} />}
    />
  );
};
