import React from 'react';
import { Text } from 'shared/components/Text/Text';
import './Label.css';

export const Label = ({ children, className = '' }) => {
  return (
    <label className="cmp-label">
      {typeof children === 'string' ? <Text className={className}>{children}</Text> : children}
    </label>
  );
};
