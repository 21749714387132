import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { Box, Text } from 'shared/components';
import { formatDurationFromNow } from 'shared/service/utils';
import { IChat } from '../../models/chat.model';
import './ChatCard.scss';

interface IProps {
  chat: IChat;
}

export const ChatCard: FC<IProps> = ({ chat }) => {
  const { timestamp } = chat.lastMsg;

  return (
    <Box flexX className="chat-item-container">
      <Avatar variant="rounded" className="chat-item-container__avatar">
        <AccountBoxIcon color="inherit" />
      </Avatar>
      <Box flexY className="chat-item-container__details">
        <div className="chat-item-container__participant">
          <Text
            bold
            multiline={false}
            thin={false}
            size={Text.sizes.l}
            className=""
            color="var(--dark-indigo)"
          >
            {chat.header}
          </Text>
        </div>
        <Box flexX className="chat-item-container__last-message">
          <Text
            className="chat-item-container__last-message-text"
            color="var(--dusk)"
            size={Text.sizes.s}
            bold={false}
            thin={false}
            multiline={false}
          >
            {chat.lastMsg?.message}
          </Text>
          <div className="chat-item-container__last-message-time">
            {formatDurationFromNow(String(timestamp).length === 10 ? timestamp * 1000 : timestamp)}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
