import React from 'react';
import { useController } from 'react-hook-form';
import { dateUtils } from 'shared/service';
import { GenericControl } from '../GenericControl/GenericControl';
import { Label } from '../Label/Label';
import TextField from '@material-ui/core/TextField';

// Returns seconds
export const InputTime = ({ label, name, control, min }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (e) => {
    onChange(dateUtils.parseTimeToSeconds(e?.target?.value));
  };

  return (
    <GenericControl>
      {label && <Label>{label}</Label>}
      <TextField
        inputProps={{ type: 'time', min: dateUtils.secondsToTimeString(min) }}
        variant="outlined"
        value={dateUtils.secondsToTimeString(value)}
        onChange={handleChange}
        margin="dense"
      />
    </GenericControl>
  );
};

InputTime.displayName = 'InputTime';
