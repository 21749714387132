import { apiService, LocalStorage } from 'shared/service';

export async function login(loginData) {
  const { access_token } = await apiService.apiFetch('auth/login', {
    body: loginData,
    method: 'POST',
    options: { skipToken: true },
  });
  LocalStorage.token.set(access_token);
}
