import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardMainTitle, CardSection, Input, Text } from 'shared/components';
import { ActionButton } from 'shared/components/Button/ActionButton';
import { CommonPage } from '../CommonPage/CommonPage';
import { emailValidation } from '../../utils/validations';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });
  const handleFormSubmit = (data) => console.log(data);

  const validationErrorMsg = errors[Object.keys(errors)[0]]?.message;

  return (
    <CommonPage>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="reset-page__form">
        <CardMainTitle>{t('AUTH_STRINGS.forgot.title')}</CardMainTitle>
        <CardSection>
          <p>
            <Text>{t('AUTH_STRINGS.forgot.description')}</Text>
          </p>
          <Input
            type="email"
            label={t('AUTH_STRINGS.form.email')}
            {...register('email', emailValidation())}
            placeholder={t('AUTH_STRINGS.form.email_placeholder')}
          />
          {errors?.email && <p>{errors?.email?.message}</p>}
        </CardSection>
        <ActionButton fullWidth type="submit" disabled={!!validationErrorMsg}>
          {t('AUTH_STRINGS.forgot.submit')}
        </ActionButton>
      </form>
    </CommonPage>
  );
};
