import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { Input, ActionButton, CardMainTitle, CardSection, Text } from 'shared/components';
import { config } from 'config/config';
import { ROUTES } from 'shared/constants';
import { emailValidation, passwordValidation } from '../../utils/validations';
import { login } from '../../../auth';
import { CommonPage } from '../CommonPage/CommonPage';
import './LoginEmail.css';

export const LoginEmail = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const history = useHistory();

  const handleFormSubmit = async (loginData) => {
    await login(loginData);
    history.replace(ROUTES.topics);
  };

  const isValidationError = isEmpty(errors);

  return (
    <CommonPage>
      {/* <Link to={ROUTES.auth.auth} className="auth-email-page__choose-another">
        Выбрать другой способ
      </Link> */}

      <form onSubmit={handleSubmit(handleFormSubmit)} className="auth-email-page__form">
        <CardMainTitle>{t('AUTH_STRINGS.login_title')}</CardMainTitle>
        <CardSection>
          <Input
            {...register('username', emailValidation())}
            label={t('AUTH_STRINGS.form.email')}
            placeholder={t('AUTH_STRINGS.form.email_placeholder')}
          />
          {errors?.email && <p>{errors?.email?.message}</p>}

          <Input
            label={t('AUTH_STRINGS.form.password')}
            type="password"
            {...register('password', passwordValidation())}
            placeholder={t('AUTH_STRINGS.form.password')}
          />
          {errors?.password && <p>{errors?.password?.message}</p>}
          <Link to={ROUTES.auth.resetPassword} className="login-form__forgot-password">
            {t('AUTH_STRINGS.form.forgot_question')}
          </Link>
        </CardSection>

        <CardSection>
          <ActionButton fullWidth type="submit" disabled={!isValidationError}>
            {t('AUTH_STRINGS.form.submit')}
          </ActionButton>
        </CardSection>
        <CardSection className="auth-form__contact">
          <Text>{t('AUTH_STRINGS.form.questions')}</Text>
          &nbsp;
          <a href={`mailto:${config.contactMail}`}>
            <Text bold color={Text.colors.darkIndigo}>
              {config.contactMail}
            </Text>
          </a>
        </CardSection>
      </form>
    </CommonPage>
  );
};
