export const WS_EVENTS_OUT = {
  chatStarted: 'chatStarted',
  msgSent: 'msgSent',
};

export const WS_EVENTS_IN = {
  chatStartedAck: 'chatStartedAck',
  msgSentAck: 'msgSentAck',
  msgReceived: 'msgReceived',
};
