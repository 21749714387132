export const isDateFuture = (date) => {
  return date.getTime() >= new Date().getTime();
};

export const getPercent = (value, sum) => {
  if (sum === undefined) {
    return Math.round(value * 100);
  }
  return Math.round((value / sum) * 100);
};
