import React from 'react';
import classNames from 'classnames';
import TextField, { BaseTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import { GenericControl } from '../GenericControl/GenericControl';
import { Label } from '../Label/Label';

interface IProps extends Omit<TextFieldProps, 'error'> {
  label: string;
  error?: string | boolean;
  value?: string;
  rows?: number;
  helperText?: string;
  size?: BaseTextFieldProps['size'];
}

export const Textarea = React.forwardRef<HTMLInputElement, IProps>(
  ({ label, error, size, ...rest }, ref) => {
    return (
      <GenericControl>
        {label && <Label className="field-label">{label}</Label>}
        {
          <TextField
            ref={ref}
            fullWidth
            className={classNames('message-field', 'field-control')}
            variant="outlined"
            color="primary"
            error={Boolean(error)}
            multiline
            rows={8}
            size={size}
            {...rest}
          />
        }
        {/* {error && <ErrorMessage>{error}</ErrorMessage>}  // TODO need? */}
      </GenericControl>
    );
  },
);

Textarea.displayName = 'Textarea';
