/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import React, { KeyboardEventHandler, Ref } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core';
import { GenericControl } from '../GenericControl/GenericControl';
import { Label } from '../Label/Label';
import './Input.css';

interface IProps {
  autoFocus?: boolean;
  label?: string;
  error?: string | boolean;
  className?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  InputProps?: InputProps;
  onKeyEnter?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  ref: Ref;
  [x: string]: unknown;
}

export const Input = React.forwardRef<Ref, IProps>(
  ({ autoFocus = false, label, error, onKeyEnter, className, ...rest }, ref) => {
    const handleKeyDown: KeyboardEventHandler = (e) => {
      if (e.key === 'Enter') {
        onKeyEnter && onKeyEnter();
      }
    };
    return (
      <GenericControl className={className}>
        {label && <Label className="field-label">{label}</Label>}

        <TextField
          autoFocus={autoFocus}
          inputRef={ref}
          onKeyDown={handleKeyDown}
          size="small"
          fullWidth
          variant="outlined"
          color="primary"
          error={error}
          {...rest}
        />

        {/* {error && <ErrorMessage>{error}</ErrorMessage>}  // TODO need? */}
      </GenericControl>
    );
  },
);

Input.displayName = 'Input';
