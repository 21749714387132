import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text } from 'shared/components';
import './NavItem.css';

export const NavItem = ({ text, description, to }) => {
  return (
    <Link to={to}>
      <Box className="nav-item" flexY>
        <Text size={Text.sizes.l} bold className="nav-item__text">
          {text}
        </Text>
        <Text size={Text.sizes.xs} className="nav-item__text">
          {description}
        </Text>
      </Box>
    </Link>
  );
};
