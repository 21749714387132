const AUTH_STRINGS_RUS = {
  login_title: 'Войдите на сайт Occolo',
  form: {
    email: 'Электронная почта',
    email_placeholder: 'Адрес электронной почты',
    password: 'Пароль',
    forgot_question: 'Забыли пароль?',
    submit: 'Вход в сайт',
    questions: 'В случае возникновения вопросов, пожалуйста, обращайтесь',
  },
  forgot: {
    title: 'Сброс пароля',
    description: 'Введите свой адрес электронной почты, и мы вышлем вам дальнейшие инструкции',
    submit: 'Сбросить',
  },
  validation: {
    email: 'Электронная почта обязательна.',
    emailInvalid: 'Введенный адрес электронной почты недействительный',
    password: 'Пароль обязателен.',
    passwordMinLength: 'Пароль слишком короткий.',
    passwordMaxLength: 'Длина пароля должна быть менее 255 символов.',
  },
};

export default AUTH_STRINGS_RUS;
