import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginEmail, ResetPassword } from '../../modules/auth';
import { ROUTES } from 'shared/constants';

export const LoginPage = () => {
  return (
    <div>
      <Switch>
        <Route path={ROUTES.auth.resetPassword} component={ResetPassword} />
        <Route path={ROUTES.auth.email} component={LoginEmail} />
        {/* <Route path={ROUTES.auth.auth} component={LoginMethods} /> */}
        <Redirect path={ROUTES.auth.auth} to={ROUTES.auth.email} />
      </Switch>
    </div>
  );
};
