import { ROUTES } from 'shared/constants';

const loginOptionsList = [
  {
    name: 'Mobile - ID',
    path: ROUTES.auth.mobileId,
  },
  {
    name: 'Smart - ID',
    path: ROUTES.auth.smartId,
  },
  {
    name: 'ID card',
    path: ROUTES.auth.idCard,
  },
  {
    name: 'Email',
    path: ROUTES.auth.email,
  },
];

export default loginOptionsList;
