import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, LoadingWrapper, ModuleContent, Text } from 'shared/components';
import { ROUTES } from 'shared/constants';
import { LocalStorage } from 'shared/service';
import { useGetMyAccountQuery } from '../../redux';
import { NavItem } from '../NavItem/NavItem';
import { Avatar } from '../Avatar/Avatar';
import { UserTitleName } from '../title/UserTitle';
import './AccountView.css';

export const AccountView = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetMyAccountQuery();
  const { path } = useRouteMatch();
  const handleLogout = () => {
    LocalStorage.token.set('');
    window.location.href = ROUTES.auth.auth;
  };
  return (
    <ModuleContent>
      <LoadingWrapper isLoading={isLoading}>
        <Card>
          <Box className="account-view">
            <p>
              <Text size={Text.sizes.xxxl} bold>
                {t('ACCOUNT_STRINGS.__module_title')}
              </Text>
            </p>
            <p>
              <Text size={Text.sizes.l} thin multiline>
                {t('ACCOUNT_STRINGS.__module_desc')}
              </Text>
            </p>
          </Box>
          <Box flexY className="account-view__user">
            <Avatar />
            <UserTitleName user={data} />
            <Text>{data?.email}</Text>
          </Box>
        </Card>
        <Card className="account-view__navigation">
          <Box>
            <NavItem
              text={t('ACCOUNT_STRINGS.messages.__title')}
              description={t('ACCOUNT_STRINGS.messages.__description')}
              to={`${path}/chats`}
            />
          </Box>
          <Box>
            <NavItem
              text={t('ACCOUNT_STRINGS.settings.__title')}
              description={t('ACCOUNT_STRINGS.settings.__description')}
              to={`${path}/settings`}
            />
          </Box>
        </Card>
        <Box className="account-view__logout" onClick={handleLogout}>
          <Button fullWidth>{t('ACCOUNT_STRINGS.logout')}</Button>
        </Box>
      </LoadingWrapper>
    </ModuleContent>
  );
};
