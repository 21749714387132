const ACCOUNT_STRINGS_ENG = {
  __module_title: 'Personal Account',
  __module_desc: 'Manage your personal data',
  apartment: { __title: 'Apartments', __description: '' },
  car: { __title: 'Car', __description: '' },
  privacy: { __title: 'Privacy', __description: '' },
  notifications: { __title: 'Notifications', __description: '' },
  messages: { __title: 'Messages', __description: 'Personal messages' },
  settings: { __title: 'Settings', __description: 'Account settings' },
  form: {
    __title: 'Personal Information',
    firstName: 'First name',
    lastName: 'Last name',
    nickname: 'Nickname',
    phoneNumber: 'Phone number',
    carNumber: 'License plate',
    email: 'Email',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    new_password_repeat: 'Repeat the new password',
    errors: {
      passwords_not_same: 'Passwords do not match!',
    },
    submit_change_password: 'Change',
    submit_update: 'Save the changes',
  },
  logout: 'Logout',
};

export default ACCOUNT_STRINGS_ENG;
