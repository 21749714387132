import React, { useEffect, VFC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BackRouting,
  BottomSticky,
  Box,
  Text,
  ModuleContent,
  Card,
  CardMainTitle,
  CardSection,
  LoadingWrapper,
} from 'shared/components';
import { usePagination } from 'shared/hooks/pagination';
import { SortKey, useSorting } from 'shared/hooks/sorting';
import { Author } from './author/Author';
import { useFetchTopicQuery, useCreateCommentMutation } from '../../redux';
import { CommentSender } from '../comments';
import { CommentsPages } from '../comments/Pages/CommentsPages';
import './TopicView.css';

export const TopicView: VFC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: topicResponse, isFetching: loading } = useFetchTopicQuery(id);
  const commentsPagination = usePagination();
  const commentsSorting = useSorting(SortKey.DESC);

  const [createComment, { isLoading: isCreatingComment }] = useCreateCommentMutation();

  const handleSendComment = (text: string): void => {
    createComment({ topicId: id, text });
  };

  useEffect(() => {
    commentsPagination.reset();
  }, [id]);

  if (!topicResponse || !topicResponse.data) {
    return null;
  }

  const { data: topic } = topicResponse;

  return (
    <LoadingWrapper isLoading={loading}>
      <ModuleContent>
        <Box flexY gap>
          <Box>
            <BackRouting text={t('TOPICS_STRINGS.topic.back')} />
          </Box>
          <Box>
            <p>
              <Text size={Text.sizes.xxxl} bold>
                {topic.title}
              </Text>
            </p>
            <p>
              <Text size={Text.sizes.l} thin multiline>
                {topic.description}
              </Text>
            </p>
          </Box>
          <Card>
            <CardSection>
              <CardMainTitle />
            </CardSection>
            <Text>{t('TOPICS_STRINGS.topic.messageCounter', { count: topic.commentsCount })}</Text>
            <Box>
              <Author user={topic.author} createdAt={topic.createdAt} />
            </Box>
          </Card>
          <Box className="topic-view__comments">
            <CommentsPages
              pagination={{ ...commentsPagination, total: topic.commentsCount }}
              sort={commentsSorting.sort}
              topicId={id}
            />
          </Box>
        </Box>

        <BottomSticky>
          <CommentSender loading={isCreatingComment} onSend={handleSendComment} />
        </BottomSticky>
      </ModuleContent>
    </LoadingWrapper>
  );
};
