import React from 'react';
import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';
import { IoClose } from 'react-icons/io5';
import './FormHeader.css';

export const FormHeader = ({ text, closeTo }) => {
  return (
    <div className="cmp-form-header">
      <Text size={Text.sizes.xxxl}>{text}</Text>
      {closeTo && <Button icon={<IoClose />} to={closeTo} />}
    </div>
  );
};
