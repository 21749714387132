import i18n from 'i18n';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidation = () => ({
  // pattern: {
  //   value: EMAIL_REGEX,
  //   message: i18n.t('AUTH_STRINGS.validation.emailInvalid'),
  // },
  required: {
    value: true,
    message: i18n.t('AUTH_STRINGS.validation.email'),
  },
});

export const passwordValidation = () => ({
  required: {
    value: true,
    message: i18n.t('AUTH_STRINGS.validation.password'),
  },
  // minLength: {
  //   value: 8,
  //   message: i18n.t('AUTH_STRINGS.validation.passwordMinLength'),
  // },
  maxLength: {
    value: 255,
    message: i18n.t('AUTH_STRINGS.validation.passwordMaxLength'),
  },
});
