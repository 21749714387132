import React, { useState } from 'react';
import { Box, Card, LoadingWrapper } from 'shared/components';
import {
  useGetMyAccountQuery,
  useSetPasswordMutation,
  useUpdateMyAccountMutation,
} from '../../redux';
import { SettingsForm } from './SettingsForm';
import { SetPasswordForm } from './SetPasswordForm';
import { SetPasswordSwitch } from './SetPasswordSwitch';
import './Settings.css';

export const Settings = () => {
  const { data, isLoading } = useGetMyAccountQuery();
  const [showPassReset, setShowPassReset] = useState(false);
  const [updateAccount, { isLoading: isUpdating }] = useUpdateMyAccountMutation();
  const [setPassword, { isLoading: isSettingPassword }] = useSetPasswordMutation();

  const onSubmit = async ({ carNumber, phoneNumber }) => {
    updateAccount({
      phoneNumber: phoneNumber && { isVisible: true, value: phoneNumber },
      carNumber: carNumber && { isVisible: true, value: carNumber },
    });
  };

  const handleSetPassword = async ({ old_password: oldPassword, new_password: password }) => {
    try {
      await setPassword({ oldPassword, password, email: data.email });
    } finally {
      setShowPassReset(false);
    }
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Card>
        {data && <SettingsForm data={data} loading={isUpdating} onSubmit={onSubmit} />}
        <Box className="settings__set-password" bordered>
          <SetPasswordSwitch
            isActive={showPassReset}
            onChange={() => setShowPassReset(!showPassReset)}
          />
          {showPassReset && (
            <SetPasswordForm onSubmit={handleSetPassword} isLoading={isSettingPassword} />
          )}
        </Box>
      </Card>
    </LoadingWrapper>
  );
};
