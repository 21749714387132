import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text/Text';
import './CardMainTitle.css';

export const CardMainTitle = ({ children = '' }) => {
  const { t } = useTranslation();
  return (
    <Text size={Text.sizes.xl} bold className="card-main-title__text">
      {children || t('SHARED_STRINGS.card.main_info')}
    </Text>
  );
};
