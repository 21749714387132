import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tag } from 'shared/components';
import classNames from 'classnames';
import { PollListTags } from 'modules/polls/utils';
import './PollFilterTags.css';

interface IProps {
  tag?: string | null;
  onChange: (tagName: string) => void;
}

const tagNames = Object.values(PollListTags); // as (keyof typeof PollListTags)[];

export const PollFilterTags: FC<IProps> = ({ tag, onChange }) => {
  const { t } = useTranslation();
  return (
    <Box flexX className="polls-filters">
      {tagNames
        .filter((tagName) => tagName !== PollListTags.CLOSED)
        .map((tagName) => (
          <Tag
            className={classNames('poll-tag', { 'poll-tag--active': tagName === tag })}
            key={tagName}
            onClick={() => onChange(tagName)}
          >
            {t(`POLLS_STRINGS.tags.${tagName}`)}
          </Tag>
        ))}
    </Box>
  );
};
