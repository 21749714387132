import { Tags } from '../utils/constants';

export const TOPICS_STRINGS_RUS = {
  topics: 'Обсуждения',
  topics_count: 'Сейчас ведется {{topicsCount}} обсуждений',
  tags: {
    [Tags.active]: 'Активные',
    [Tags.all]: 'Все',
    [Tags.urgent]: 'Срочные',
    [Tags.new]: 'Новые',
    [Tags.closed]: 'Закрытые',
  },
  create_topic: 'Создать обсуждение',
  filter: {
    header: 'Заголовок',
    setFromDate: 'Обновленные с',
    setToDate: 'Обновленные до',
    apply: 'Применить фильтры',
    reset: 'Сбросить фильтры',
  },
  form: {
    header: 'Создать обсуждение',
    title: 'Заголовок',
    description: 'Текст',
    activeUntil: 'Дедлайн',
    setActiveUntil: 'Установить дедлайн',
    changeActiveUntil: 'Сменить дедлайн',
    publish: 'Опубликовать обсуждение',
  },
  topic: {
    author: 'Автор',
    back: 'Назад к обсуждениям',
    messageCounter: '{{count}} сообщений',
    created: 'Создано',
    comments: 'Комментарии',
    inputPlaceholder: 'Напишите что-нибудь',
    lastCommentedAt: 'Последний комментарий',
    loading: 'Загрузка',
  },
};

export default TOPICS_STRINGS_RUS;
