import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardSection, Input, Text } from 'shared/components';
import { DateFilterParams, FiltersButtons, FiltersDates } from 'shared/components/module-blocks';
import { IPollFilters } from '../../types/types';

interface IProps {
  filters?: IPollFilters;
  loading?: boolean;
  onChange: (filters: IPollFilters) => void;
  onReset: () => void;
}

export const PollFilters: FC<IProps> = ({ filters = {}, loading, onChange, onReset }) => {
  const { t } = useTranslation();
  const [localFilters, setLocalFilters] = useState<IPollFilters>(filters);
  const {
    filterActiveFrom,
    filterActiveTo,
    filterFromDate,
    filterHeaderText,
    filterToDate,
  } = localFilters;

  const handleFilterDateChange = ({ from, to }: DateFilterParams) => {
    const newDateFilters: { filterFromDate?: number; filterToDate?: number } = {};
    if (from) {
      newDateFilters.filterFromDate = from;
    }
    if (to) {
      newDateFilters.filterToDate = to;
    }
    setLocalFilters({ ...localFilters, ...newDateFilters });
  };

  const handleApply = () => {
    onChange(localFilters);
  };

  return (
    <Card onClick={null}>
      <CardSection>
        <Text size={Text.sizes.xl}>{t('SHARED_STRINGS.filters.__title')}</Text>
      </CardSection>
      <Input
        placeholder={t('POLLS_STRINGS.filter.header')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setLocalFilters({ filterHeaderText: event.currentTarget.value });
        }}
        onKeyEnter={handleApply}
        value={filterHeaderText || ''}
        label={t('SHARED_STRINGS.search')}
      />

      <FiltersDates from={filterActiveFrom} to={filterToDate} onChange={handleFilterDateChange} />

      <FiltersButtons loading={loading} onReset={onReset} onApply={handleApply} />
    </Card>
  );
};
