import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Box } from 'shared/components';
import './CardSection.css';

interface IProps {
  className?: string;
  spaceBetween?: boolean;
  flexX?: boolean;
  [x: string]: unknown;
}

export const CardSection: FunctionComponent<IProps> = ({
  className,
  spaceBetween,
  children,
  ...props
}) => {
  return (
    <Box
      className={classNames(
        'cmp-card-section',
        { 'cmp-card-section--space-between': spaceBetween },
        className,
      )}
      {...props}
    >
      {children}
    </Box>
  );
};
