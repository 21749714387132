import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LocalStorage } from './local-storage';
import { config } from 'config/config';
import { PAGINATION, ROUTES } from 'shared/constants';
import { Logger } from 'shared/service/';

async function handleResponse(response) {
  if (response.status === 401) {
    // logout();
    window.location.assign(ROUTES.auth.auth);
    return;
  }
  if (response.ok) {
    return await response.json();
  } else {
    const errorMessage = await response.text();
    return Promise.reject(new Error(errorMessage));
  }
}

async function apiFetch(endpoint, { body, options: { skipToken } = {}, ...configProps }) {
  const token = LocalStorage.token.get();
  const headers = { 'content-type': 'application/json' };
  if (token && !skipToken) {
    headers.Authorization = `Bearer ${token}`;
  }
  const fetchConfig = {
    ...configProps,
    headers: {
      ...headers,
      ...configProps.headers,
    },
  };
  if (body) {
    fetchConfig.body = JSON.stringify(body);
  }

  try {
    const response = await window.fetch(`${config.api.baseUrl}/${endpoint}`, fetchConfig);

    return await handleResponse(response);
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

const rtkFetch = fetchBaseQuery({
  baseUrl: config.api.baseUrl,
  prepareHeaders: (headers) => {
    const token = LocalStorage.token.get();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

function rtkGetUrlParams(params) {
  return new URLSearchParams({
    ...params.filters,
    pageNumber: params.pagination.page,
    limit: params.pagination.limit,
    sort: params.sort,
  }).toString();
}

const apiService = {
  apiFetch,
  rtkFetch,
  rtkGetUrlParams,
  read: (endpoint, params) =>
    apiFetch(
      `${endpoint}${params ? '?' : ''}${
        params
          ? new URLSearchParams({
              ...params.filters,
              pageNumber: params.pagination.page,
              limit: params.pagination.limit,
            })
          : ''
      }`,
      {
        method: 'GET',
      },
    ),
  create: (endpoint, data) => apiFetch(endpoint, { body: data, method: 'POST' }),
  update: (endpoint, data) => apiFetch(endpoint, { body: data, method: 'PUT' }),
  delete: (endpoint) => apiFetch(endpoint, { method: 'DELETE' }),
  getDefaultPagination: () => ({ page: PAGINATION.startPage, limit: PAGINATION.limit }),
  getDefaultSort: () => ({ sort: 'desc' }),
  isListLoaded: ({ page, limit, total }) => Math.trunc((total - 1) / limit) === page,
};

export { apiService };
