import React from 'react';
import classNames from 'classnames';
import { Box } from 'shared/components';
import './ModuleContent.css';

export const ModuleContent = ({ light = true, children }) => {
  return (
    <Box
      className={classNames('cmp-module-content', {
        'cmp-module-content--light': light,
      })}
    >
      {children}
    </Box>
  );
};
