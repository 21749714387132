// Utilize generic list component from src/components and persumably list-hook from shared/hooks

import React, { FC } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListItems } from 'shared/components';
import { ITopic } from '../../types';
import { TopicCard } from '../card/TopicCard';

interface IProps {
  topics: ITopic[];
  loading: boolean;
}

export const TopicsList: FC<IProps> = ({ loading, topics = [] }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const handleClick = (topic: ITopic) => {
    history.push(`${url}/${topic.topicId}`);
  };
  return (
    <ListItems>
      {topics.map((topic) => (
        <TopicCard key={topic.topicId} topic={topic} onClick={handleClick} />
      ))}
      {loading && 'Loading...'}
    </ListItems>
  );
};
