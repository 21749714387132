import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EventForm } from '../form/EventForm';
import { EventView } from '../event-view/EventView';
import { EventsView } from '../events-view/EventsView';

export const EventsRouter = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`} component={EventForm} />=
      <Route exact path={`${path}/:id`} component={EventView} />
      <Route path={`${path}`} component={EventsView} />
    </Switch>
  );
};
