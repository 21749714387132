import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardMainTitle, ListItems } from 'shared/components';
import { ResultItem } from '../result-item/ResultItem';
import { QuestionTitle } from '../../questions/QuestionTitle';
import { IQuestion } from '../../../types/models';

interface IProps {
  questions: IQuestion[];
  finishDate?: number;
}

export const ResultsList: FC<IProps> = ({ questions, finishDate }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardMainTitle>{t('POLLS_STRINGS.poll.voting')}</CardMainTitle>
      <ListItems>
        {questions.map((question, index) => (
          <ResultItem
            key={question.questionId}
            question={question}
            finishDate={finishDate}
            questionTitle={
              <QuestionTitle number={index + 1} allCount={questions.length} title={question.text} />
            }
          />
        ))}
      </ListItems>
    </Card>
  );
};
