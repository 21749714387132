import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components';
import { getPercent } from 'shared/service/util';
import { AnswerLine } from '../../answers/AnswerLine';

interface IProps {
  text: string;
  value: number | null;
  even: boolean;
  [x: string]: unknown;
}

export const AnswerResult: FC<IProps> = ({ text, value, even, ...props }) => {
  const { t } = useTranslation();
  return (
    <AnswerLine
      even={even}
      text={text}
      control={
        <Text>{value === null ? t('SHARED_STRINGS.not_available') : `${getPercent(value)} %`}</Text>
      }
      {...props}
    />
  );
};
