import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Tag } from 'shared/components';
import { eventUtils } from '../../utils';
import { putFilters, eventsSelectors, refetchEvents } from '../../redux';
import './TagFilters.css';

export const TagFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pagination = useSelector(eventsSelectors.pagination);
  const filtersType = useSelector(eventsSelectors.filtersType);
  const filters = useSelector(eventsSelectors.filters);

  const handleSelect = (key) => async () => {
    dispatch(putFilters({ type: key, ...eventUtils.getFilterByType(key) }));
  };

  useEffect(() => {
    dispatch(refetchEvents({ pagination, filters }));
  }, [filtersType]);

  return (
    <Box flexX className="event-tag-filters">
      {Object.keys(eventUtils.EVENT_FILTER_KEYS).map((key) => (
        <Box key={key} {...(filtersType !== key ? { onClick: handleSelect(key) } : {})}>
          <Tag selected={key === filtersType}>{t(`EVENTS_STRINGS.type.${key}`)}</Tag>
        </Box>
      ))}
    </Box>
  );
};
