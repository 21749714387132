import React, { FC } from 'react';
import { ListItems } from 'shared/components';
import { CommentCard } from '../Card/CommentCard';
import { IComment } from '../../../types';

interface IProps {
  comments: IComment[];
  loading: boolean;
}

export const CommentsList: FC<IProps> = ({ comments, loading }) => {
  if (!comments) {
    return null;
  }
  return (
    <ListItems className="comments-list">
      {comments.map((comment) => (
        <CommentCard key={comment.commentId} comment={comment} />
      ))}
      {loading && 'Loading...'}
    </ListItems>
  );
};
