import React, { FC } from 'react';
import { SortKey } from 'shared/hooks/sorting';
import { useFetchCommentsQuery } from '../../../redux';
import { CommentsList } from '../List/CommentsList';

interface ICommentsPageProps {
  page: number;
  limit: number;
  sort: SortKey;
  topicId: string;
}

export const CommentsPage: FC<ICommentsPageProps> = ({ topicId, page, limit, sort }) => {
  const { data: commentsData, isFetching } = useFetchCommentsQuery({
    topicId,
    pagination: { limit, page },
    sort,
  });

  if (!commentsData?.data) return null;

  return <CommentsList comments={commentsData.data} loading={isFetching} />;
};
