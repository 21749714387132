import React, { PropsWithChildren, ReactElement } from 'react';
import { Progress } from '../Progress';

interface IProps {
  isLoading: boolean;
}

export const LoadingWrapper = ({
  isLoading = false,
  children,
}: PropsWithChildren<IProps>): ReactElement => {
  return <>{isLoading ? <Progress /> : children}</>;
};
