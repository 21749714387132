import React, { FC, useEffect, useRef } from 'react';

import { ListItems } from 'shared/components';
import { MessageItem } from '../MessageItem';
import { IChatMessage } from '../../../../models/chat.model';
import './ChatMain.scss';

interface IProps {
  children: IChatMessage[];
}

export const ChatMain: FC<IProps> = ({ children = [] }) => {
  const chatBottomRef = useRef<HTMLDivElement>(null);
  const messagesCountRef = useRef<number>(children.length);

  useEffect(() => {
    if (children.length !== messagesCountRef.current) {
      chatBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      messagesCountRef.current = children.length;
    }
  }, [children.length]);

  return (
    <ListItems className="list-items-container">
      {children.map((message) => (
        <MessageItem key={`${message.id}_${message.timestamp}`} data={message} />
      ))}
      <div className="list-items-container__bottom-target" ref={chatBottomRef} />
    </ListItems>
  );
};
