const ACCOUNT_STRINGS_RUS = {
  __module_title: 'Личный кабинет',
  __module_desc: 'Управляйте своими личными данными',
  apartment: { __title: 'Квартиры', __description: '' },
  car: { __title: 'Автомобиль', __description: '' },
  privacy: { __title: 'Конфиденциальность', __description: '' },
  notifications: { __title: 'Уведомления', __description: '' },
  messages: { __title: 'Сообщения', __description: 'Личные сообщения' },
  settings: { __title: 'Настройки', __description: 'Настройки личного кабинета' },
  form: {
    __title: 'Личная информация',
    firstName: 'Имя',
    lastName: 'Фамилия',
    nickname: 'Никнейм',
    phoneNumber: 'Мобильный телефон',
    carNumber: 'Автомобиль',
    email: 'Емэйл',
    change_password: 'Изменить пароль',
    old_password: 'Старый пароль',
    new_password: 'Новый пароль',
    new_password_repeat: 'Подтвердить новый пароль',
    errors: {
      passwords_not_same: 'Введенные пароли не совпадают',
    },
    submit_change_password: 'Изменить',
    submit_update: 'Сохранить изменения',
  },
  logout: 'Выход из системы',
};

export default ACCOUNT_STRINGS_RUS;
