import React from 'react';
import { Box, Card } from 'shared/components';
import { ReactComponent as Logo } from 'assets/logo.svg';
import './CommonPage.css';

export const CommonPage = ({ children }) => {
  return (
    <div className="auth-common-page">
      <Box flexX className="auth-common-page__logo">
        <Logo />
      </Box>
      <Card className="auth-common-page__form-card">{children}</Card>
    </div>
  );
};
