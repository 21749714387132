import React, { FC, MouseEvent, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { buttonVariants } from './variants';
import './Button.scss';

export interface IProps {
  className?: string;
  type?: 'button' | 'submit';
  variant?: string;
  icon?: string | ReactElement;
  fullWidth?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  to?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const Button: FC<IProps> = ({
  className = '',
  type = 'button',
  variant = buttonVariants.CONTAINED,
  icon,
  fullWidth = false,
  children = '',
  onClick,
  to = undefined,
  loading = false,
  ...rest
}) => {
  const history = useHistory();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (to) {
      history.push(to);
    }
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };
  return (
    <button
      type={type}
      className={classNames(
        {
          'cmp-button--fullwidth': fullWidth,
          'cmp-button--icon': Boolean(icon),
          'cmp-button--contained': variant === buttonVariants.CONTAINED,
          'cmp-button--outlined': variant === buttonVariants.OUTLINED,
          'cmp-button--text': variant === buttonVariants.TEXT,
        },
        'cmp-button',
        className,
      )}
      onClick={handleClick}
      {...rest}
    >
      {icon || children}
      {loading && 'L...'}
    </button>
  );
};
