import React from 'react';
import classNames from 'classnames';
import { Box } from 'shared/components';
import './Card.css';

export const Card = ({ className = '', onClick = null, children }) => {
  return (
    <Box className={classNames('cmp-card', className)} onClick={onClick}>
      {children}
    </Box>
  );
};
