import React, { useState, VFC } from 'react';
import { useGetInitDataQuery } from 'modules/accounts/redux/account.slice';
import { ReactComponent as FeedbackFormIcon } from 'assets/icons/feedback-form.svg';
import { ReactComponent as FeedbackFormCloseIcon } from 'assets/icons/feedback-form-close.svg';
import { IconButton } from '@material-ui/core';
import { getUrl } from './getUrl';
import classNames from 'classnames';
import './FeedbackForm.scss';

export const FeedbackForm: VFC = () => {
  const [emptyRender, setEmptyRender] = useState(false);
  const { data } = useGetInitDataQuery();
  const accountName = data?.userData.email || '';

  const openForm = () => {
    window.open(getUrl(accountName));
  };

  const close = () => {
    setEmptyRender(true);
  };

  if (emptyRender) {
    return null;
  }

  return (
    <div className={classNames('feedback-form')}>
      <IconButton aria-label="feedback-form button" edge="end" onClick={openForm}>
        <FeedbackFormIcon />
      </IconButton>{' '}
      <IconButton className={classNames('feedback-form__close')} size={'small'} onClick={close}>
        <FeedbackFormCloseIcon />
      </IconButton>
    </div>
  );
};
