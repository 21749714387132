import React, { FC } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListItems } from 'shared/components';
import { PollCard } from '../card/PollCard';
import { isPollFinish } from '../../utils';
import { IPoll } from '../../types/models';

interface IProps {
  polls: IPoll[];
  loading: boolean;
}

export const PollsList: FC<IProps> = ({ loading, polls = [] }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleClick = (poll: IPoll) => {
    if (isPollFinish(poll?.activeUntil)) {
      history.push(`${url}/${poll.pollId}/results`);
    } else {
      history.push(`${url}/${poll.pollId}`);
    }
  };
  return (
    <ListItems>
      {polls.map((poll) => (
        <PollCard key={poll.pollId} poll={poll} onClick={handleClick} />
      ))}
      {loading && 'Loading...'}
    </ListItems>
  );
};
