const MESSAGES_STRINGS_RUS = {
  title: 'Сообщения',
  noMessages: 'У вас нет чатов с указанными критериями',
  writeToNeighbor: 'Вы можете создать новый чат, чтобы написать своему соседу',
  ok: 'OK',
  createChat: '+ Новый чат',
  newChat: 'Новый чат',
  send: 'Отправить',
  sendMessageToLabel: 'Отправить сообщение',
  subjectLabel: 'Тема',
  messageLabel: 'Сообщение',
  search: 'Поиск',
  typeMessagePlaceholder: 'Введите сообщение',
  selectPersonPlaceholder: 'Выберите человека',
  errors: {
    requiredField: 'Поле обязятельно для заполнения',
    lengthLess50: 'Длина поля должна быть не более 50 символов',
  },
};

export default MESSAGES_STRINGS_RUS;
