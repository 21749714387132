interface ILanguageOption {
  label: string;
  value: string;
}

export const languageOptions: ILanguageOption[] = [
  {
    label: 'EST',
    value: 'et',
  },
  {
    label: 'RUS',
    value: 'ru',
  },
  {
    label: 'ENG',
    value: 'en',
  },
];
