import React, { FC } from 'react';
import { Box, Text } from 'shared/components';
import './QuestionTitle.css';

interface IProps {
  number: number;
  allCount: number;
  title: string;
}

export const QuestionTitle: FC<IProps> = ({ number, allCount, title }) => {
  return (
    <Box flexX gap>
      <Box className="poll-question__numbers">
        <Text className="poll-question-title__number-main">{number}</Text>
        <Text className="poll-question-title__number-rest">{`/${allCount}`}</Text>
      </Box>
      <Box>
        <Text className="poll-question-title__title">{title}</Text>
      </Box>
    </Box>
  );
};
