import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'shared/components';
import { MenuIcons } from '../Navigation/icons';
import './NavigationItem.css';

const NavigationItem = ({ option }) => {
  const { t } = useTranslation();
  const handleClick = (e) => {
    if (option.disabled) {
      e.preventDefault();
    }
  };

  const IconComponent = MenuIcons[option.iconName];
  return (
    <NavLink
      className={'navigation-item'}
      activeClassName="navigation-item__selected"
      to={option.route}
      onClick={handleClick}
    >
      <Box flexX className="navigation-item__content">
        <Box flexX className="navigation-item__icon">
          <IconComponent />
        </Box>
        <Text size={Text.sizes.l} className="navigation-item__label">
          {t(`NAVIGATION_STRINGS.${option.label}`)}
        </Text>
      </Box>
    </NavLink>
  );
};

export default NavigationItem;
