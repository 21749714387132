import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ActionButton,
  buttonVariants,
  Card,
  CardSection,
  ErrorMessage,
  InputRadio,
  CardMainTitle,
} from 'shared/components';
import { SignApprovalModal } from '../../sign-approval/SignApprovalModal';
import { QuestionTitle } from '../../questions/QuestionTitle';
import { AnswerLine } from '../../answers/AnswerLine';
import { IAnswer, IQuestion } from '../../../types/models';
import './AnswersForm.css';

interface IProps {
  questions: IQuestion[];
  loading: boolean;
  onSubmit: (values: AnswerFormValues) => Promise<void>;
}

export type AnswerFormValues = {
  [questionId: string]: IAnswer['answerId'];
};

export const AnswersForm: FC<IProps> = ({ questions, loading, onSubmit }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    getValues,
  } = useForm<AnswerFormValues>({ mode: 'onChange' });

  const [approveModalOpen, setapproveModalOpen] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const handleButtonClick = () => {
    if (!isValid) {
      setShowErrors(true);
    } else {
      setapproveModalOpen(true);
    }
  };

  const handleModalDecline = () => {
    setapproveModalOpen(false);
  };
  const handleSubmitPoll = () => {
    const formValues = getValues();
    onSubmit(formValues);
    setapproveModalOpen(false);
  };

  const getAnswers = () => {
    const formValues = getValues();
    return questions.map((q) => ({
      ...q,
      answers: q.answers.map((answer) => ({
        ...answer,
        isChosen: formValues[q.questionId] === answer.answerId,
      })),
    }));
  };

  return (
    <Card>
      <CardMainTitle>{t('POLLS_STRINGS.poll.voting')}</CardMainTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SignApprovalModal
          isOpen={approveModalOpen}
          formData={getAnswers()}
          onApprove={handleSubmitPoll}
          onDecline={handleModalDecline}
        />
        {questions.map((question, index) => (
          <CardSection key={question.questionId}>
            <Box className="answers-form__question">
              <QuestionTitle number={index + 1} allCount={questions.length} title={question.text} />
              <InputRadio
                title={question.text}
                name={question.questionId}
                options={question.answers.map(({ answerId, text }) => ({
                  id: answerId || text,
                  text,
                }))}
                renderOption={({ text, index, control }) => (
                  <AnswerLine text={text} even={index % 2 === 0} control={control} />
                )}
                control={control}
                required
              />
              {showErrors && errors[question.questionId] && (
                <ErrorMessage>{errors[question.questionId]?.type}</ErrorMessage>
              )}
            </Box>
          </CardSection>
        ))}
        <ActionButton fullWidth variant={buttonVariants.OUTLINED} onClick={handleButtonClick}>
          {t('POLLS_STRINGS.poll.vote')}
        </ActionButton>
      </form>
    </Card>
  );
};
