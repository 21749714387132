import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { Box, Button, buttonVariants, Input, Text } from 'shared/components';
import { Label } from 'shared/components/form-controls/Label/Label';
import { PollFormValues } from '../PollForm';
import { IQuestion } from '../../../types/models';
import './Question.css';

type QuestionErrorPath<T> = { [x: string]: { text: T } };
type AnswerErrorPath<T> = { [x: string]: { answers: { [x: string]: T } } };

interface IProps {
  question: IQuestion;
  questionIndex: number;
  control: Control;
  register: UseFormRegister<PollFormValues>;
  errors: QuestionErrorPath<{ type: unknown }> & AnswerErrorPath<{ type: unknown }>;
}

export const Question: FC<IProps> = ({ question, questionIndex, control, register, errors }) => {
  const { t } = useTranslation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions[${questionIndex}].answers`,
  });
  return (
    <Box className="poll-question">
      <Input
        label={t('POLLS_STRINGS.form.title')}
        {...register(`questions.${questionIndex}.text`, { required: true })}
        defaultValue={question.text}
        error={errors?.[questionIndex]?.text?.type}
      />
      <Label>{t('POLLS_STRINGS.form.answers')}</Label>
      <Box className="poll-question__answers">
        <ul>
          {fields.map((item, answerIndex) => (
            <li key={item.id} className="poll-question__answer">
              <Input
                {...register(`questions.${questionIndex}.answers.${answerIndex}`, {
                  required: true,
                })}
                // defaultValue={item.field1}
                error={errors?.[questionIndex]?.answers?.[answerIndex]?.type}
              />
              <Button icon={<IoClose />} onClick={() => remove(answerIndex)} />
            </li>
          ))}
          <Button variant={buttonVariants.TEXT} onClick={() => append('')}>
            <Text>{`+ ${t('POLLS_STRINGS.form.addAnswer')}`}</Text>
          </Button>
        </ul>
      </Box>
    </Box>
  );
};
