import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, ListItems } from 'shared/components';
import { EventCard } from '../card/EventCard';

export const EventsList = ({ loading, events = [] }) => {
  let { url } = useRouteMatch();
  const history = useHistory();
  const handleClick = (event) => {
    history.push(`${url}/${event.eventId}`);
  };
  return (
    <ListItems className="events-list">
      {events.map((event) => (
        <Box key={event.eventId} onClick={() => handleClick(event)}>
          <EventCard event={event} />
        </Box>
      ))}
      {loading && 'Loading...'}
    </ListItems>
  );
};
