import React, { VFC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PollForm } from '../form/PollForm';
import { PollView } from '../poll-view/PollView';
import { PollsView } from '../polls-view/PollsView';
import { PollResults } from '../poll-results/PollResults';

export const PollsRouter: VFC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/new`} component={PollForm} />
      <Route exact path={`${path}/:id/results`} component={PollResults} />
      <Route exact path={`${path}/:id`} component={PollView} />
      <Route path={`${path}`} component={PollsView} />
    </Switch>
  );
};
