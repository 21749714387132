import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { AccountView } from '../AccountView/AccountView';
import { AccountSubPage } from '../AccountSubPage/AccountSubPage';
import { Apartment } from '../Apartment/Apartment';
import { Car } from '../Car/Car';
import { Confidential } from '../Confidential/Confidential';
import { Notifications } from '../Notifications/Notifications';
import { Messages } from '../Messages/Messages';
import { Settings } from '../Settings/Settings';
import { Chat } from '../Messages/components';

export const AccountRouter = () => {
  return (
    <Switch>
      <Route path={ROUTES.account.account} exact>
        <AccountView />
      </Route>
      <Route path={ROUTES.account.apartment}>
        <AccountSubPage>
          <Apartment />
        </AccountSubPage>
      </Route>
      <Route path={ROUTES.account.car}>
        <AccountSubPage>
          <Car />
        </AccountSubPage>
      </Route>
      <Route path={ROUTES.account.confidential}>
        <AccountSubPage>
          <Confidential />
        </AccountSubPage>
      </Route>
      <Route path={ROUTES.account.notifications}>
        <AccountSubPage>
          <Notifications />
        </AccountSubPage>
      </Route>
      <Route path={ROUTES.account.chats} exact>
        <AccountSubPage>
          <Messages />
        </AccountSubPage>
      </Route>
      <Route path={ROUTES.account.chat}>
        <Chat />
      </Route>
      <Route path={ROUTES.account.settings}>
        <AccountSubPage>
          <Settings />
        </AccountSubPage>
      </Route>
    </Switch>
  );
};
