import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getInitials, UserTitle } from 'modules/accounts';
import { Box, Text } from 'shared/components';
import { Avatar } from 'shared/components/Avatar/Avatar';
import { renderDate } from 'shared/service/locales';
import { IUser } from '../../../types';
import './Author.css';

interface IProps {
  user: IUser;
  createdAt?: number;
}

export const Author: FC<IProps> = ({ user, createdAt }) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames('topic-view-author')}>
      <Box className="topic-view-author__avatar">
        <Avatar className="topic-view-author__avatar__content">{getInitials(user)}</Avatar>
      </Box>

      <Box
        className={classNames('topic-view-author__user-title', {
          'topic-view-author--single-line': !createdAt,
        })}
      >
        <UserTitle user={user} />
      </Box>
      {createdAt && (
        <Box className="topic-view-author__created-at">
          <Text>{`${t('TOPICS_STRINGS.topic.created')}: ${renderDate(createdAt)}`}</Text>
        </Box>
      )}
    </Box>
  );
};
