import React from 'react';
import { Box, Text } from 'shared/components';
import { EMPTY_USER_APARTMENT } from '../../utils/constants';
import './UserTitle.css';

export const UserTitleName = ({ user }) => (
  <Text
    size={Text.sizes.l}
    bold
    className="user-title__name"
  >{`${user?.firstName} ${user?.lastName}`}</Text>
);
export const UserTitle = ({ user }) => {
  return (
    <Box className="user-title">
      <UserTitleName user={user} />
      <Text className="user-title__apartment">{user?.apartment || EMPTY_USER_APARTMENT}</Text>
    </Box>
  );
};
