import React, { useEffect, useState, VFC } from 'react';
import { Box, ModuleContent } from 'shared/components';
import { getPollFilterByTag, PollListTags } from '../../utils';
import { PollsViewHeader } from '../polls-view-header/PollsViewHeader';
import { PollFilterTags } from 'modules/polls/components/filter/tags/PollFilterTags';
import { PollFilters } from 'modules/polls/components/filter/PollFilters';
import { useFetchPollsQueryState } from 'modules/polls/redux/polls.slice';
import { EntityFilters, useEntityFilters } from 'shared/hooks/filters';
import { usePagination } from 'shared/hooks/pagination';
import { SortKey, useSorting } from 'shared/hooks/sorting';
import { isEmpty } from 'lodash';
import { PollsViewPages } from './PollsPages';
import './PollsView.css';

export const PollsView: VFC = () => {
  const [showFilters, setshowFilters] = useState(false);
  const [tag, setTag] = useState<string | null>(PollListTags.ALL);
  const { limit, page, setNextPage, reset } = usePagination();
  const { filters, resetFilters } = useEntityFilters<EntityFilters>();
  const { sort } = useSorting(SortKey.DESC);

  const pollsLastPageData = useFetchPollsQueryState({
    pagination: { limit, page },
    filters,
    sort,
  });

  const total = pollsLastPageData.data?.metadata.total || 0;

  const onFilterChange = (newFilters: EntityFilters) => {
    resetFilters(newFilters);
    setTag(null);
  };

  const handleFiltersReset = () => {
    onFilterChange({});
    reset();
    setshowFilters(false);
    setTag(PollListTags.ALL);
  };

  const handleTagChange = (tagName: string) => {
    setTag(tagName);
    reset();
    resetFilters(getPollFilterByTag(tagName));
  };

  useEffect(() => {
    reset();
  }, [filters]);

  return (
    <ModuleContent>
      <Box flexY className="polls-view-header">
        <PollsViewHeader
          pollsCount={total}
          filterActive={filters && !isEmpty(filters)}
          onFilterClick={() => setshowFilters(!showFilters)}
        />
        {showFilters && (
          <Box>
            <PollFilters filters={filters} onChange={onFilterChange} onReset={handleFiltersReset} />
          </Box>
        )}
        <PollFilterTags tag={tag} onChange={handleTagChange} />
      </Box>
      <PollsViewPages
        pagination={{ page, limit, total, setNextPage }}
        filters={filters}
        sort={sort}
      />
    </ModuleContent>
  );
};
