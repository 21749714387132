import React, { useContext, useCallback, useEffect, useState, VoidFunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { RootState } from 'config/store';
import { WS_EVENTS_OUT } from 'shared/service/socket';
import {
  Box,
  LoadingWrapper,
  SocketContext,
  Heading,
  BottomSticky,
  Button,
  ListItems,
  ListItem,
  buttonVariants,
  Text,
} from 'shared/components';
import { ISocketContext } from 'shared/components/SocketProvider/SocketContext';
import { refetchChats, fetchChats } from './redux/chats.slice';
import { chatsSelectors } from './redux/chats.selectors';
import { ChatCard } from './components/ChatCard';
import { CreateChatModal } from './components/CreateChatModal';
import { ChatSearchFilter } from './components/ChatSearchFilter';
import { ICreateChatEvent } from './models/event.model';
import { IChat } from './models/chat.model';
import { ILoadingState, IPagination } from './redux/models';
import './Messages.scss';

export const Messages: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const { sendMessage }: ISocketContext = useContext(SocketContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector<RootState, ILoadingState>(chatsSelectors.loading)?.fetchAll;
  const chats = useSelector<RootState, IChat[]>(chatsSelectors.selectAll);
  const pagination = useSelector<RootState, IPagination>(chatsSelectors.pagination);

  const onCreateChat = () => setOpenModal(true);
  const onDecline = () => setOpenModal(false);

  useEffect(() => {
    dispatch(refetchChats());
  }, [dispatch]);

  useBottomScrollListener((): void => {
    if (!pagination.loadedAll && !isLoading) {
      dispatch(fetchChats({ filters: { filterHeaderText: searchText, sort: 'desc' }, pagination }));
    }
  });

  const onApprove = (payload: ICreateChatEvent) => {
    sendMessage && sendMessage(WS_EVENTS_OUT.chatStarted, payload);
    setOpenModal(false);
  };

  const debounced = useCallback(
    debounce(
      (query: string) =>
        dispatch(refetchChats({ filters: { filterHeaderText: query, sort: 'desc' } })),
      400,
    ),
    [],
  );

  const onSearchChange = (query: string): void => {
    setSearchText(query);
    debounced(query);
  };

  const onChatClick = (id: string) => () => history.push(`/account/chats/${id}`);

  return (
    <Box flexY className="chats-container">
      <Box className="chats-container__heading">
        <Heading>{t('MESSAGES_STRINGS.title')}</Heading>
      </Box>
      <Box className="chats-container__search">
        <ChatSearchFilter value={searchText} onChange={onSearchChange} />
      </Box>
      <Box flexY className="chats-container__chat-list-wrapper">
        <LoadingWrapper isLoading={isLoading && !chats.length}>
          {chats.length > 0 ? (
            <ListItems dense className="chats-container__chat-list">
              {chats.map((chat: IChat) => (
                <ListItem
                  key={chat.id}
                  button
                  className="chats-container__chat-item"
                  onClick={onChatClick(chat.id)}
                >
                  <ChatCard chat={chat} />
                </ListItem>
              ))}
            </ListItems>
          ) : (
            <Text
              bold={false}
              thin={true}
              color="var(--blue-grey)"
              size={Text.sizes.l}
              multiline
              className="chats-container__no-messages"
            >
              <div>{t('MESSAGES_STRINGS.noMessages')}</div>
              <div>{t('MESSAGES_STRINGS.writeToNeighbor')}</div>
            </Text>
          )}
        </LoadingWrapper>
      </Box>
      <BottomSticky>
        <Box flexX className="chats-container__create-chat-btn-wrapper">
          <Button
            variant={buttonVariants.CONTAINED}
            className="chats-container__create-chat-btn"
            onClick={onCreateChat}
            fullWidth
          >
            {t('MESSAGES_STRINGS.createChat')}
          </Button>
        </Box>
      </BottomSticky>
      <CreateChatModal isOpen={isOpenModal} onApprove={onApprove} onDecline={onDecline} />
    </Box>
  );
};
