import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { Box, Text } from 'shared/components';
import './AnswerLine.css';

interface IProps {
  text: string;
  even: boolean;
  className?: string;
  control: ReactElement;
}

export const AnswerLine: FC<IProps> = ({ text, even, className, control: ControlComponent }) => {
  return (
    <Box className={classNames('answer-line', className, { 'answer-line--even': even })} flexX gap>
      <Box className="answer-line__control">{ControlComponent}</Box>
      <Text className="answer-line__text">{text}</Text>
    </Box>
  );
};
