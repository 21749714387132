import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFilterOutline } from '@react-icons/all-files/io5/IoFilterOutline';
import { Button, Text } from 'shared/components';
import { colors } from 'config/styles';
import './TopicsViewHeader.css';

interface IProps {
  topicsCount: number;
  filterActive: boolean;
  onFilterClick: () => void;
}

export const TopicsViewHeader: FC<IProps> = ({
  topicsCount,
  filterActive = false,
  onFilterClick,
}) => {
  const { t } = useTranslation();
  const iconParams = filterActive ? { color: colors.dark.primary, fontWeight: 'bold' } : {};

  return (
    <div className="topics-view-header__container">
      <Text size={Text.sizes.xxxl} className="topics-view-header__header">
        {t('TOPICS_STRINGS.topics')}
      </Text>
      <div className="topics-view-header__block">
        <Text size={Text.sizes.l} thin>
          {t('TOPICS_STRINGS.topics_count', { topicsCount })}
        </Text>
        <Button icon={<IoFilterOutline {...iconParams} />} onClick={onFilterClick}></Button>
      </div>
    </div>
  );
};
