import React, { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import classNames from 'classnames';
import { Box, Text } from 'shared/components';
import { formatDate } from 'shared/service/utils';
import { IChatMessage } from '../../../../models/chat.model';
import './MessageItem.scss';

interface IProps {
  data: IChatMessage;
}

export const MessageItem: FC<IProps> = ({ data }) => {
  const { isMine, message, timestamp } = data;

  return (
    <Box flexY className="message-item">
      <Box
        flexX
        className={classNames('message-item__inner-container', {
          'message-item__inner-container--mine': isMine,
        })}
      >
        <Avatar variant="rounded" className="message-item__avatar">
          <AccountBoxIcon color="inherit" />
        </Avatar>
        <Box
          flexY
          className={classNames('message-item__text-container', {
            'message-item__text-container--mine': isMine,
          })}
        >
          <Text
            className={classNames('message-item__text', { 'message-item__text--mine': isMine })}
            size={Text.sizes.m}
            color="var(--dark-indigo)"
            multiline
            bold={false}
            thin={false}
          >
            {message}
          </Text>
          <Text
            className="message-item__time"
            color="var(--blue-grey)"
            bold={false}
            thin={false}
            multiline={false}
            size={Text.sizes.s}
          >
            {formatDate(
              String(timestamp).length === 10 ? timestamp * 1000 : timestamp,
              'dd/MM/yyyy HH:mm',
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
