import React, { FC } from 'react';
import { useScrollObserver } from './scroll';

interface IProps {
  onDetect: () => void;
}

export const ScrollDetector: FC<IProps> = ({ onDetect }) => {
  const setTargetScroll = useScrollObserver(onDetect);

  return <div ref={setTargetScroll}></div>;
};
