const SHARED_RUS = {
  search: 'Поиск',
  not_available: 'Н/Д',
  filters: {
    __title: 'Фильтр',
    reset: 'Очистить Фильтр',
    apply: 'Показать',
  },
  form: {
    title: 'Заголовок',
    description: 'Описание',
    text: 'Текст',
    date: 'Установите срок исполнения',
  },
  card: {
    main_info: 'Основная информация',
  },
  backToAccount: 'Вернуться к аккаунту',
  displayingDate: '{{timestamp, ddd MMM D YYYY}}',
};

export default SHARED_RUS;
