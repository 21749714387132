const SHARED_EST = {
  search: 'Search',
  not_available: 'N/A',
  filters: {
    __title: 'Filter',
    reset: 'Clear filter',
    apply: 'Show',
  },
  form: {
    title: 'Title',
    description: 'Description',
    text: 'Text',
    date: 'Set due date',
  },
  card: {
    main_info: 'Main info',
  },
  backToAccount: 'Back to account',
  displayingDate: '{{timestamp, ddd MMM D YYYY}}',
};

export default SHARED_EST;
