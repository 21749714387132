import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserTitle } from 'modules/accounts';
import { Box, Card, Text } from 'shared/components';
import { renderDate } from 'shared/service/locales';
import { IComment } from '../../../types';
import './CommentCard.css';

interface IProps {
  comment: IComment;
}

export const CommentCard: FC<IProps> = ({ comment }) => {
  const { t } = useTranslation();

  return (
    <Card className="comment-card">
      <Box className="comment-card__avatar"></Box>
      <Box className="comment-card__author">
        <UserTitle user={comment.author} />
      </Box>

      {comment.createdAt && (
        <Text className="comment-card__created-at">{renderDate(comment.createdAt)}</Text>
      )}

      <Text multiline className="comment-card__text">
        {comment.text}
      </Text>
    </Card>
  );
};
