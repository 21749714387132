import React, { FC, ReactElement, useEffect } from 'react';
import { Control, useController } from 'react-hook-form';
import { Box, Text } from 'shared/components';

interface IOptionProps {
  id: string;
  title: string;
  text: string;
  value: string;
  onSelect: React.ChangeEventHandler<HTMLInputElement>;
}

const Option: FC<IOptionProps> = ({ id, title, text, value, onSelect }) => (
  <Box>
    <label>
      <input
        type="radio"
        id={id}
        name={title}
        value={id}
        checked={value === id}
        onChange={onSelect}
      />
      <Text>{text}</Text>
    </label>
  </Box>
);

interface IInputRadioProps {
  control: Control;
  title: string;
  name: string;
  options: { id: string; text: string }[];
  required: boolean;
  renderOption: (props: {
    control: ReactElement;
    text: string;
    index: number;
    id: string;
  }) => ReactElement;
}

export const InputRadio: FC<IInputRadioProps> = ({
  control,
  title,
  name,
  options,
  required,
  renderOption,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: { required: required },
  });

  useEffect(() => {
    onChange('');
  }, []);

  const handleRadioChange = (newValue: string) => () => {
    onChange(newValue);
  };
  return (
    <Box>
      {options.map(({ id, text }, index) =>
        renderOption ? (
          <label key={id}>
            {renderOption({
              text,
              id,
              index,
              control: (
                <input
                  type="radio"
                  id={id}
                  name={title}
                  value={id}
                  checked={value === id}
                  onChange={handleRadioChange(id)}
                />
              ),
            })}
          </label>
        ) : (
          <Option
            key={id}
            id={id}
            value={value}
            title={title}
            text={text}
            onSelect={handleRadioChange(id)}
          />
        ),
      )}
    </Box>
  );
};
