export const DEFAULT_COORDS = { lat: 53.893009, lng: 27.567444 };

export const TAGS = {
  active: 'Active',
  all: 'All',
  urgent: 'Urgent',
  closed: 'closed',
  new: 'new',
};

export const PAGINATION = {
  startPage: 0,
  limit: 10,
};

export const ROUTES = {
  auth: {
    auth: '/auth',
    resetPassword: '/auth/reset-password',
    mobileId: '/auth/login-mobile-id',
    smartId: '/auth/login-smart-id',
    idCard: '/auth/login-id-card',
    email: '/auth/login-email',
  },
  main: '/main',
  topics: '/topics',
  polls: '/polls',
  events: '/events',
  news: '/news',
  docs: '/docs',
  account: {
    account: '/account',
    apartment: '/account/apartment',
    car: '/account/car',
    confidential: '/account/confidential',
    notifications: '/account/notifications',
    chats: '/account/chats',
    chat: '/account/chats/:id',
    settings: '/account/settings',
  },
};
