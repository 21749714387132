import { dateUtils } from 'shared';

const isEnded = (event) => {
  return event.endsAt < Number(new Date()) / 1000;
};

const EVENT_TYPES = {
  official: 'official',
  unofficial: 'unofficial',
};

const EVENT_FILTER_KEYS = {
  all: 'all',
  official: 'official',
  unofficial: 'unofficial',
  ended: 'ended',
};

function getFilterByType(key) {
  switch (key) {
    case EVENT_FILTER_KEYS.all:
      return {};
    case EVENT_FILTER_KEYS.official:
      return { filterIsOfficial: 1 };
    case EVENT_FILTER_KEYS.unofficial:
      return { filterIsOfficial: 0 };
    case EVENT_FILTER_KEYS.ended:
      return { filterToDate: dateUtils.getTimestamp(new Date()) };
    default:
      break;
  }
}

export const eventUtils = { isEnded, EVENT_TYPES, EVENT_FILTER_KEYS, getFilterByType };
