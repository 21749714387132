import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tag } from 'shared/components';
import { Tags } from '../../../utils/constants';

import './TopicFilterTags.css';

interface IProps {
  tag?: string | null;
  onChange: (tagName: string) => void;
}

const tagNames = Object.keys(Tags) as (keyof typeof Tags)[];

export const TopicFilterTags: FC<IProps> = ({ tag, onChange }) => {
  const { t } = useTranslation();
  return (
    <div>
      {tagNames.map((tagName) => (
        <Tag
          className={classNames('topic-tag', { 'topic-tag--active': tagName === tag })}
          key={tagName}
          onClick={() => onChange(tagName)}
        >
          {t(`TOPICS_STRINGS.tags.${tagName}`)}
        </Tag>
      ))}
    </div>
  );
};
