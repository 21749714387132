import POLLS_STRINGS from '../modules/polls/locales/poll-est';
import TOPICS_STRINGS from '../modules/topics/locales/topic-est';
import EVENTS_STRINGS from '../modules/events/locales/event-est';
import NAVIGATION_STRINGS from '../modules/navigation/locales/nav-est';
import AUTH_STRINGS from '../modules/auth/locales/auth-est';
import ACCOUNT_STRINGS from '../modules/accounts/locales/accounts-est';
import MESSAGES_STRINGS from '../modules/accounts/components/Messages/locales/messages-est';
import SHARED_STRINGS from '../shared/locales/shared-est';

export default {
  POLLS_STRINGS,
  TOPICS_STRINGS,
  EVENTS_STRINGS,
  NAVIGATION_STRINGS,
  AUTH_STRINGS,
  ACCOUNT_STRINGS,
  MESSAGES_STRINGS,
  SHARED_STRINGS,
};
