import React, { ReactElement, VoidFunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box } from 'shared/components';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ROUTES } from 'shared/constants';
import { Navigation } from 'modules/navigation';
import { chatsSelectors } from 'modules/accounts/components/Messages/redux/chats.selectors';
import { fontSizeMap, MAX_NOTIFICATIONS_TO_DISPLAY } from './const';
import { formatCounterValue, getFontSize } from './helpers';
import './AppHeader.scss';

export const AppHeader: VoidFunctionComponent = (): ReactElement => {
  const user = null;
  const notifications = useSelector(chatsSelectors.notifications) ?? [];
  const count: number = notifications.reduce((acc, notification) => acc + notification.count, 0);
  const size = `${getFontSize(count, MAX_NOTIFICATIONS_TO_DISPLAY, fontSizeMap)}px`;

  return (
    <Box flexX className="app-header fixed">
      <Box flexX className="app-header__group">
        <Navigation />
      </Box>
      <Box flexX className="app-header__group">
        <Box flexX className="app-header__notifications">
          <Bell />
          {count > 0 && (
            <div
              style={{ fontSize: size, lineHeight: size }}
              className="app-header__notification-count"
            >
              {formatCounterValue(count, MAX_NOTIFICATIONS_TO_DISPLAY)}
            </div>
          )}
        </Box>
        <div>
          <NavLink to={ROUTES.account.account} activeClassName="app-header__account--selected">
            <Avatar>{user}</Avatar>
          </NavLink>
        </div>
      </Box>
    </Box>
  );
};
