import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionButton, FormHeader, Input } from 'shared/components';

export const SettingsForm = ({ data, loading, onSubmit }) => {
  const { t } = useTranslation();
  const { firstName, lastName, email, phoneNumber, carNumber } = data;
  const defaultValues = {
    firstName,
    lastName,
    email,
    phoneNumber: phoneNumber?.value,
    carNumber: carNumber?.value,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange', defaultValues });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormHeader text={t('ACCOUNT_STRINGS.form.__title')} />
      <Input
        {...register('firstName', { required: true, maxLength: 150 })}
        label={t('ACCOUNT_STRINGS.form.firstName')}
        readOnly
        error={errors?.firstName?.message || errors?.firstName?.type}
      />
      <Input
        {...register('lastName', { required: true, maxLength: 150 })}
        label={t('ACCOUNT_STRINGS.form.lastName')}
        readOnly
        error={errors?.lastName?.message || errors?.lastName?.type}
      />
      <Input
        {...register('nickname', { maxLength: 150 })}
        label={t('ACCOUNT_STRINGS.form.nickname')}
        readOnly
        error={errors?.nickname?.message || errors?.nickname?.type}
      />
      <Input
        {...register('phoneNumber', { maxLength: 12 })}
        type="tel"
        label={t('ACCOUNT_STRINGS.form.phoneNumber')}
        error={errors?.phoneNumber?.message || errors?.phoneNumber?.type}
      />
      <Input
        {...register('carNumber', { maxLength: 12 })}
        label={t('ACCOUNT_STRINGS.form.carNumber')}
        error={errors?.carNumber?.message || errors?.carNumber?.type}
      />
      <ActionButton fullWidth type="submit" loading={loading}>
        {t('ACCOUNT_STRINGS.form.submit_update')}
      </ActionButton>
    </form>
  );
};
