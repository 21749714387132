import React, { FC } from 'react';
import { Box } from 'shared/components';
import { ScrollDetector } from 'shared/hooks/scroll/ScrollDetector';
import { SortKey } from 'shared/hooks/sorting';
import { CommentsPage } from './CommentsPage';

interface IProps {
  pagination: { page: number; limit: number; total: number; setNextPage: () => void };
  sort: SortKey;
  topicId: string;
}

export const CommentsPages: FC<IProps> = ({
  pagination: { page, limit, total, setNextPage },
  sort,
  topicId,
}) => {
  const pageNumberList = new Array(page + 1 + 1).fill(null).map((i, index) => index);

  return (
    <Box>
      {pageNumberList.map((pageNumber) => (
        <CommentsPage
          key={pageNumber}
          page={pageNumber}
          limit={limit}
          sort={sort}
          topicId={topicId}
        />
      ))}

      {total / (page + 1 + 1) > limit && <ScrollDetector onDetect={setNextPage} />}
    </Box>
  );
};
