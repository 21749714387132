const EVENTS_STRINGS_RUS = {
  events: 'Встречи',
  type: {
    all: 'Все',
    official: 'Официальная',
    unofficial: 'Неофициальная',
    ended: 'Завершенные',
  },
  form: {
    header: 'Создать встречу',
    title: 'Заголовок',
    description: 'Описание',
    date: 'Укажите дату',
    time: 'Время встречи',
    topic: 'Связать с обсуждением',
    publish: 'Опубликовать встречу',
  },
  event: {
    author: 'Автор',
    back: 'Назад к встречам',
    is_ended: 'Завершена',
    date: 'Дата',
    discuss: 'Обсудить встречу',
    createDiscussion: 'Создать обсуждение',
  },
  create_event: 'Создать встречу',
  loading: 'Загрузка...',
};

export default EVENTS_STRINGS_RUS;
