import React, { FC, ReactElement } from 'react';
import { default as MuiAvatar } from '@material-ui/core/Avatar';

interface IProps {
  className?: string;
  children: ReactElement | string | null;
}

export const Avatar: FC<IProps> = ({ children, className }) => {
  return <MuiAvatar classes={{ root: className }}>{children}</MuiAvatar>;
};
