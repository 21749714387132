import { useState } from 'react';

export type PaginationHookModel = {
  page: number;
  limit: number;
  setNextPage: () => void;
  setPreviousPage: () => void;
  reset: () => void;
};

export const usePagination = (): PaginationHookModel => {
  const [page, setPage] = useState<number>(0);

  const setNextPage = () => {
    setPage((currentPage) => currentPage + 1);
  };

  const setPreviousPage = () => {
    setPage(page - 1);
  };
  const reset = () => {
    setPage(0);
  };
  return { page, limit: 10, setNextPage, setPreviousPage, reset };
};
