import React, { useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core//MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { styled } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { languageOptions } from '../../constants/languageOptions';
import { Box } from 'shared/components';
import { ReactComponent as Language } from 'assets/icons/language.svg';
import './LanguageSelect.css';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#35287e',
    color: 'rgba(255, 255, 255, 0.25)',
    fontSize: 16,
    padding: '10px 26px 10px 20px',
    '&:focus': {
      // borderRadius: 4,
    },
  },
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
}));

const LanguageSelect: FC = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>('');

  useEffect(() => {
    const detectedLanguage = i18n.resolvedLanguage;
    setLanguage(detectedLanguage);
  }, []);

  const handleChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    localStorage.setItem('i18nextLng', `${event.target.value}`);
    i18n.changeLanguage(event.target.value as string);
    setLanguage(event.target.value as string);
  };
  return (
    <Box flexX className="language-container">
      <Box flexX className="language_icon">
        <Language />
      </Box>
      <FormControl fullWidth>
        <Select
          value={language}
          onChange={handleChangeLanguage}
          inputProps={{ 'aria-label': 'Without label' }}
          input={<BootstrapInput />}
        >
          {languageOptions.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelect;
