import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { Box } from 'shared/components';
import { AppHeader } from './components';
import { TopicsRouter } from 'modules/topics';
import { PollsRouter } from 'modules/polls';
import { EventsRouter } from 'modules/events';
import { AccountRouter } from 'modules/accounts';
import { SocketProvider } from 'shared/components/SocketProvider';
import { useGetInitDataQuery } from 'modules/accounts/redux/account.slice';
import { setNotifications } from 'modules/accounts/components/Messages/redux/chats.slice';
import { FeedbackForm } from './components/Feedback/FeedbackForm';
import './AppRootPage.css';

export const AppRootPage = () => {
  const { data } = useGetInitDataQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setNotifications(data.notifications));
    }
  }, [data]);

  return (
    <SocketProvider>
      <Box flexY className="app-root-page">
        <AppHeader />
        <Box flexY className="app-root-page__content">
          <Switch>
            <Route path={ROUTES.topics} component={TopicsRouter} />
            <Route path={ROUTES.polls} component={PollsRouter} />
            <Route path={ROUTES.events} component={EventsRouter} />
            <Route path={ROUTES.account.account} component={AccountRouter} />
            <Redirect path="/" to={ROUTES.auth.auth} />
          </Switch>
          <FeedbackForm />
        </Box>
      </Box>
    </SocketProvider>
  );
};
