import React, { FC } from 'react';
import classNames from 'classnames';
import './Box.css';

interface IProps {
  className?: string;
  flexX?: boolean;
  flexY?: boolean;
  gap?: boolean;
  gapDouble?: boolean;
  bordered?: boolean;
  onClick?: () => void;
}

export const Box: FC<IProps> = ({
  className = '',
  flexX,
  flexY,
  gap,
  gapDouble,
  bordered,
  onClick,
  children,
}) => {
  return (
    <div
      className={classNames(
        className,
        {
          'cmp-box--flex-x': flexX,
          'cmp-box--flex-y': flexY,
          'cmp-box--bordered': bordered,
          'cmp-box--gapped': (flexX || flexY) && gap,
          'cmp-box--gapped-double': (flexX || flexY) && gapDouble,
        },
        'cmp-box',
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
